import {
    NeoComponents
} from "@forcepoint/platform-utilityui"
import * as singleSpa from "single-spa"
import { getActionStatus } from "../../state/userprofile.selector";
import { ActionState } from "../../state/userprofile.state";

const EditProfileFooter = ({ isValidForm, handleSubmit }) => {
    const actionStatus = getActionStatus()

    return (
        <div className="editprofile-footer">
            <NeoComponents.NeoSecondaryButton
                id="up_cancel_btn"
                size="small"
                onClick={() => {
                    const url = sessionStorage.getItem("editProfilePrevUrl")
                    singleSpa.navigateToUrl(url)
                }}
            >
                Cancel
            </NeoComponents.NeoSecondaryButton>
            <NeoComponents.NeoPrimaryButton
                id="up_save_btn"
                size="small"
                isLoading={
                    actionStatus[ActionState.EDIT_USER]?.actionStatus ===
                    "pending"
                }
                isDisabled={!isValidForm}
                onClick={handleSubmit}
            >
                Save
            </NeoComponents.NeoPrimaryButton>
        </div>
    );
};

export default EditProfileFooter;