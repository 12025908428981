import {
    NeoComponents
} from "@forcepoint/platform-utilityui"

const SearchActions = ({activeApp}) => {
    return activeApp?.modules?.uiConfig?.enableSearch && (
        <li>
            <a
                id="search_link"
                onKeyDown={() => { }}
                onClick={(e) => {
                    e.preventDefault()
                }}
            >
                <NeoComponents.NeoIcon name="search1" size={22} />
            </a>
        </li >
    )
};

export default SearchActions;