import axios from "axios"
import { coreStore, APP_LOGOUT } from "@forcepoint/platform-coreui"
import { toast } from "react-toastify"
import { Messages } from "./validation-messages.constants"
import { APP_URL, APP_TENANT } from "@forcepoint/platform-coreui"

/**
 * To get state data
 */
const stateData = coreStore.getState()

/**
 * To get user token.
 */
let userToken = stateData?.userToken

/**
 * To subscribe corestore
 */
coreStore.subscribe(() => {
  const currentState = coreStore.getState()
  userToken = currentState?.userToken
})

/**
 * To get RBI tenant
 */
const rbiTenant = stateData?.userInfo?.ext?.tenantId?.find(
  (a) => a.hint.toLowerCase() === "global"
)

/**
 * To get api base url
 * @returns
 */
export const getAPIBaseUrl = () => {
  const storeData = coreStore.getState()
  const apiURL = storeData?.activeApp?.modules?.moduleUrls.apiURL
  const apiVarKey =
    apiURL !== undefined && apiURL !== null && apiURL !== ""
      ? "apiURL"
      : "prefix"
  const apiBaseUrl = `${storeData?.activeApp?.modules?.moduleUrls[apiVarKey]}/`
  return apiBaseUrl
}

/**
 * To store password policy tooltip text.
 */
export const passwordPolicyTooltipText = `Password must contain - <br>1.&nbsp;&nbsp; Minimum 8 characters. <br>2.&nbsp; Maximum 20 characters. <br>3.&nbsp; At least one upper and one lower case letter. <br>4.&nbsp; At least one digit.<br>5.&nbsp; At least one special character from the <br>&nbsp;&nbsp;&nbsp;&nbsp; following set.&nbsp; ! @ # $ % & ? = [ ] < > { }`

/**
 * To store api related constants
 */
export const API_URL_CONSTANTS = {
  IS_API: true,
  LOCAL_URL: "",
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  TENANT_ID: rbiTenant
}

/**
 * Axios requst interceptor
 */
axios.interceptors.request.use(
  async (config) => {
    if (userToken !== undefined) {
      config.headers["Authorization"] = `Bearer ${userToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * Axios response interceptor with localhost
 */
axios.interceptors.response.use(
  (response) => {
    if (response?.data?.status && response?.data?.message) {
      return Promise.reject(response.data)
    }
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      if (window.location.hostname !== "localhost") {
        coreStore.dispatch({ type: APP_LOGOUT })
      }
      return null
    }
    return Promise.reject(error?.response)
  }
)

/**
 * To store weekdays
 */
export const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
]

/**
 * To store months
 */
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

/**
 * To store date store data
 */
export const DATE_STORE = {
  START_DATE: "",
  END_DATE: "",
  FREQUENCY: 30,
  CATEGORY_TYPE: ""
}

/**
 * To set date range
 * @param startDateStr
 * @param endDateStr
 * @returns
 */
export const setDateRange = (startDateStr, endDateStr) => {
  const startDate = new Date(startDateStr)
  const endDate = new Date(endDateStr)
  return calcStartEndDate(startDate, endDate, DATE_SELECTION_LIST[7])
}

/**
 * To calculate start end date
 * @param val
 * @returns
 */
export const calculateStartEndDate = (currentPeriod: any) => {
  const sessionDateData = JSON.parse(sessionStorage.getItem("sessionDateData"))
  const endDate =
    sessionDateData?.currentPeriod?.value === "range"
      ? new Date(sessionDateData.endDate)
      : new Date()
  const days =
    sessionDateData?.category === "1H" ? 1 : sessionDateData?.category || 30
  const startDate = new Date(endDate.getTime() - days * 24 * 60 * 60 * 1000)

  return calcStartEndDate(startDate, endDate, currentPeriod)
}

/**
 * To calculate start and end date
 */
const calcStartEndDate = (startDate, endDate, currentPeriod) => {
  const endDateDate = ("0" + endDate.getDate()).slice(-2)
  const endDateMonth = ("0" + (endDate.getMonth() + 1)).slice(-2)
  const endMonthVal = MONTHS[endDate.getMonth()]

  const startDateDate = ("0" + startDate.getDate()).slice(-2)
  const startDateMonth = ("0" + (startDate.getMonth() + 1)).slice(-2)
  const startMonthVal = MONTHS[startDate.getMonth()]

  DATE_STORE.START_DATE =
    startDate.getFullYear() + "-" + startDateMonth + "-" + startDateDate
  DATE_STORE.END_DATE =
    endDate.getFullYear() + "-" + endDateMonth + "-" + endDateDate

  let category = currentPeriod.value
  if (currentPeriod.value === "range") {
    const diffTime = endDate - startDate
    category = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  }
  DATE_STORE.FREQUENCY = parseInt(category)
  DATE_STORE.CATEGORY_TYPE = category
  const sessionDateData = {
    startDate,
    endDate,
    category,
    currentPeriod
  }
  sessionStorage.setItem("sessionDateData", JSON.stringify(sessionDateData))
  return {
    startDate:
      startDateDate +
      " " +
      startMonthVal.substr(0, 3) +
      " " +
      startDate.getFullYear(),
    endDate:
      endDateDate + " " + endMonthVal.substr(0, 3) + " " + endDate.getFullYear()
  }
}

/**
 * To get results
 * @param payload
 * @param key
 * @returns
 */
export const getResults = (payload, key?) => {
  if (key + "" && payload && payload.results && payload.results[key]) {
    return payload.results[key]
  } else if (payload && payload.results) {
    return payload.results
  }
  return null
}

/**
 * Call settled api for slices
 * @param arr
 * @returns
 */
export const callSettledAPI = async (arr: Promise<any>[]) => {
  return await Promise.allSettled(arr).then((val: any[]) => {
    const areAllRejceted = val.every((a) => a.status === "rejected")
    if (areAllRejceted) {
      return Promise.reject(val)
    }
    return { promise: val, custom: true }
  })
}

/**
 * To make api call with serialized parameters
 * @param options
 * @returns
 */
export const toMakeAPIRequestWithSerialization = async (options) => {
  return await axios.request(options)
}

/**
 * To make api call
 * @param options
 * @returns
 */
export const toMakeAPIRequest = async (options) => {
  if (options.orderby) {
    options.url += options.orderby
  }
  if (
    options.url.includes("log-query-service") &&
    !options.url.includes("pagination=false") &&
    options.params &&
    options.params.pagination !== false
  ) {
    options.url = options.url + "?pagination=false"
  }
  return await axios.request(options)
}

/**
 * To get request url data
 * @param key
 * @returns
 */
export const toGetRequestUrlData = (key, apiUrl?) => {
  if (
    API_URL_CONSTANTS.IS_API &&
    key.API_SERVER !== "" &&
    key.API_SERVER !== undefined &&
    key.API_SERVER !== null
  ) {
    const urlData = {
      url: (apiUrl ? apiUrl : getAPIBaseUrl()) + key.API_SERVER,
      method: key.API_SERVER_REQUEST_TYPE
    }
    return urlData
  } else {
    const urlData = {
      url: key.LOCAL,
      method: key.LOCAL_REQUEST_TYPE
    }
    return urlData
  }
}

/**
 * To form api payload with serialization
 * @param key
 * @returns
 */
export const toFormAPIPayloadWithSerialization = (key, apiUrl?, reqObj?) => {
  const urlData = toGetRequestUrlData(key, apiUrl)
  const options: any = {
    ...urlData,
    data: {},
    params: {},
    paramsSerializer: { indexes: null }
  }
  return options
}

/**
 * To form api payload
 * @param key
 * @returns
 */
export const toFormAPIPayload = (key, apiUrl?, reqObj?) => {
  const urlData = toGetRequestUrlData(key, apiUrl)
  const options: any = {
    ...urlData,
    data: {},
    params: {
      // pagination: false
    }
  }
  if (reqObj) {
    options.orderby =
      reqObj.orderby && reqObj.orderby.length > 0
        ? "?orderby=" + reqObj.orderby.join("&orderby=")
        : undefined
    delete reqObj.orderby
  }
  return options
}

/**
 * To show toaster
 * @param toastConfig
 */
export const showToastr = (toastConfig: any) => {
  toast[toastConfig?.type](toastConfig?.message, {
    position: toastConfig.position ? toastConfig.position : "top-center",
    autoClose: toastConfig.autoClose ? toastConfig.autoClose : 5000,
    hideProgressBar: toastConfig.hideProgressBar
      ? toastConfig.hideProgressBar
      : true,
    closeOnClick: toastConfig.closeOnClick ? toastConfig.closeOnClick : true,
    pauseOnHover: toastConfig.pauseOnHover ? toastConfig.pauseOnHover : true,
    draggable: toastConfig.draggable ? toastConfig.draggable : true,
    progress: toastConfig.draggable ? toastConfig.draggable : undefined,
    theme: toastConfig.theme ? toastConfig.theme : "light"
  })
}

/**
 * Window on scroll event
 */
window.addEventListener("scroll", function () {
  if (window.scrollY === 0) {
    document.querySelector("body").classList.remove("app-scrolled")
  } else {
    document.querySelector("body").classList.add("app-scrolled")
  }
})

/**
 * To check if empty
 * @param value
 * @returns
 */
export function isEmpty(value) {
  if (value == null) {
    // Handles null and undefined values
    return true
  }

  if (Array.isArray(value) || typeof value === "string") {
    // Checks if the value is an array or string and checks its length
    return value.length === 0
  }

  if (typeof value === "object") {
    // Checks if the value is an object and checks its enumerable properties
    return Object.keys(value).length === 0
  }

  return false
}

/**
 * To validate specific form field
 * @param formObj
 * @param config
 * @returns
 */
export const validate = (formObj, config) => {
  let formErrors: any = {}
  formErrors = findChildrenAndValidate(formObj, config)
  return formErrors
}

/**
 * To validate field
 */
const validateField = (configField, formObjField) => {
  let error: any = null
  if (configField.required) {
    if (formObjField && isEmpty(formObjField?.value)) {
      error = {}
      error.type = "required"
      error.message = configField.required.msg ? configField.required.msg : null
    }
  }
  if (configField.minLength) {
    if (
      formObjField &&
      formObjField?.value?.length > 0 &&
      formObjField?.value?.length < configField.minLength.min
    ) {
      error = {}
      error.type = "minLength"
      error.message = configField.minLength.msg
        ? configField.minLength.msg
        : null
    }
  }
  if (configField.maxLength) {
    if (
      formObjField &&
      formObjField?.value?.length > configField.maxLength.max
    ) {
      error = {}
      error.type = "maxLength"
      error.message = configField.maxLength.msg
        ? configField.maxLength.msg
        : null
    }
  }
  if (configField.minValue) {
    if (
      formObjField &&
      formObjField?.value &&
      parseFloat(formObjField?.value) < configField.minValue.min
    ) {
      error = {}
      error.type = "minValue"
      error.message = configField.minValue.msg ? configField.minValue.msg : null
    }
  }
  if (configField.maxValue) {
    if (
      formObjField &&
      formObjField?.value &&
      parseFloat(formObjField?.value) > configField.maxValue.max
    ) {
      error = {}
      error.type = "maxValue"
      error.message = configField.maxValue.msg ? configField.maxValue.msg : null
    }
  }
  if (configField.pattern) {
    const regEx = new RegExp(configField.pattern.regex)
    if (
      formObjField &&
      formObjField?.value?.length > 0 &&
      !regEx.test(formObjField.value)
    ) {
      error = {}
      error.type = "pattern"
      error.message = configField.pattern.msg ? configField.pattern.msg : null
    }
  }
  return error
}

/**
 * To find children and validate
 */
const findChildrenAndValidate = (formObj, config) => {
  const tempFormErrors: any = {}
  const tempErrorsMsg: any = {}
  Object.keys(config).forEach((field) => {
    const errorMessage = validateField(config[field], formObj[field])
    if (errorMessage !== null) {
      tempFormErrors[field] = errorMessage.type
      tempErrorsMsg[field] = errorMessage.message ? errorMessage.message : null
    }
    if (config[field]?.childObject) {
      tempFormErrors[field] = {}
      tempErrorsMsg[field] = {}
      Object.keys(config[field]).forEach((field2) => {
        const errorMessage = validateField(
          config[field][field2],
          formObj[field][field2]
        )
        if (errorMessage !== null) {
          tempFormErrors[field][field2] = errorMessage.type
          tempErrorsMsg[field][field2] = errorMessage.message
            ? errorMessage.message
            : null
        }
      })
    }
  })

  const errors: any = {}
  const msg = Messages
  Object.keys(tempFormErrors).forEach((fieldName) => {
    if (formObj[fieldName].touched) {
      if (tempErrorsMsg[fieldName]) {
        errors[fieldName] = tempErrorsMsg[fieldName]
      } else {
        errors[fieldName] =
          replacedToArgs(
            msg[tempFormErrors[fieldName]],
            config[fieldName][tempFormErrors[fieldName]]
          ) + " "
      }
    } else {
      if (config[fieldName]?.childObject) {
        errors[fieldName] = {}
        Object.keys(tempFormErrors[fieldName]).forEach((fieldName2) => {
          if (formObj[fieldName][fieldName2].touched) {
            if (tempErrorsMsg[fieldName][fieldName2]) {
              errors[fieldName][fieldName2] =
                tempErrorsMsg[fieldName][fieldName2]
            } else {
              errors[fieldName][fieldName2] =
                replacedToArgs(
                  msg[tempFormErrors[fieldName][fieldName2]],
                  config[fieldName][fieldName2][
                    tempFormErrors[fieldName][fieldName2]
                  ]
                ) + " "
            }
          }
        })
      }
    }
  })
  let isValidForm = false
  if (Object.keys(tempFormErrors).length === 0) {
    isValidForm = true
  } else {
    isValidForm = Object.keys(tempFormErrors)?.every(
      (a) => Object.keys(tempFormErrors[a]).length === 0
    )
  }
  return { errors, isValidForm: isValidForm }
}

/**
 * Reaplaced to args
 * @param message
 * @param args
 * @returns
 */
const replacedToArgs = (message: string, args: { [key: string]: string }) => {
  if (args !== undefined) {
    Object.keys(args).forEach((arg) => {
      message = message.replace(new RegExp(`{${arg}}`, "g"), args[arg])
    })
    return message
  }
  return message
}

/**
 * To validate all form fields
 * @param formObj
 * @param config
 * @returns
 */
export const validateAllFormFields = (formObj, config) => {
  Object.keys(formObj).forEach((field) => {
    formObj[field].touched = true
  })
  let formErrors: any = {}
  formErrors = findChildrenAndValidate(formObj, config)
  return formErrors
}

/**
 * To format date
 * @param date
 * @param format
 * @returns
 */
export const formatDate = (date, format, type?) => {
  const formattedDate = date ? date?.replace(/-/g, "/")?.substr(0, 19) : date
  if (!isNaN(Date.parse(formattedDate))) {
    let d = new Date(formattedDate)
    if (type === "UTC") {
      const offset = d.getTimezoneOffset()
      d = new Date(d.getTime() - offset * 60000)
    }
    if (format === "DD MMM YYYY hh:mm:ss") {
      return `${("0" + d.getDate()).slice(-2)} ${MONTHS[d.getMonth()].substr(
        0,
        3
      )} ${d.getFullYear()} ${("0" + d.getHours()).slice(-2)}:${(
        "0" + d.getMinutes()
      ).slice(-2)}:${("0" + d.getSeconds()).slice(-2)}`
    } else if (format === "DD MMM YYYY") {
      return `${("0" + d.getDate()).slice(-2)} ${MONTHS[d.getMonth()].substr(
        0,
        3
      )} ${d.getFullYear()}`
    } else if (format === "DD-MMM-YYYY, H:mm") {
      return `${("0" + d.getDate()).slice(-2)} ${MONTHS[d.getMonth()].substr(
        0,
        3
      )} ${d.getFullYear()}, ${("0" + d.getHours()).slice(-2)}:${(
        "0" + d.getMinutes()
      ).slice(-2)}`
    }
  } else {
    return date
  }
}

/**
 * To get application Url
 * @returns
 */
export const getAppUrl = (appName, ignoreEULogic?) => {
  const storeData = coreStore.getState()
  const apiUrl = storeData?.activeApp?.modules?.moduleUrls["apiURL"]
  if (
    apiUrl !== "" &&
    apiUrl !== undefined &&
    window.location.hostname !== "localhost"
  ) {
    const apiBaseUrl = `${storeData?.activeApp?.modules?.moduleUrls["apiURL"]}/`
    return apiBaseUrl
  } else {
    const hostOrigin = APP_URL.split(".")
    const domain = hostOrigin.slice(-1)[0]
    if (domain !== "com" && !ignoreEULogic) {
      return `${hostOrigin[0]}-${domain}.${
        appName ? appName + "." : ""
      }${hostOrigin.slice(1, hostOrigin.length - 1).join(".")}.com/`
    } else {
      return `${hostOrigin[0]}.${appName ? appName + "." : ""}${hostOrigin
        .slice(1)
        .join(".")}/`
      // if (appName) {
      // } else {
      //   return `${hostOrigin[0]}.${hostOrigin.slice(1).join(".")}/`
      // }
    }
  }
}

/**
 * To get API baseURL using applicationCode
 */
export const getAPIBaseURL = (appCode) => {
  const storeData = coreStore.getState()
  const apiBaseURL = storeData?.applications?.find(
    (currElement) =>
      currElement.applicationCode.toLowerCase() === appCode.toLowerCase()
  ).modules[0].moduleUrls

  if (isEmpty(apiBaseURL?.apiURL)) {
    return `${apiBaseURL?.prefix}/`
  } else {
    return `${apiBaseURL?.apiURL}/`
  }
}

/**
 * To check if valid tag
 */
export const isValidTag = (str) => {
  const regex = /^[a-zA-Z0-9._-]+$/

  // Validation for operating systems
  const operatingSystemNames = [
    "windows",
    "linux",
    "mac",
    "ios",
    "android",
    "windows server",
    "ubuntu",
    "centos",
    "fedora",
    "debian",
    "red hat enterprise linux (rhel)",
    "freebsd",
    "openbsd",
    "netbsd",
    "chrome os",
    "tizen",
    "qnx",
    "haiku"
  ]

  return !!(
    regex.test(str) &&
    !operatingSystemNames.some((name) => str.toLowerCase().includes(name))
  )
}

/**
 * To calculate scope permission
 * @param str
 * @returns
 */
export const calcScopePermission = (str) => {
  let userScopeStr = coreStore.getState()?.userInfo?.scope
  userScopeStr = userScopeStr.replace(/:/g, ".")
  const isExpired = coreStore.getState()?.userInfo?.tenantStatus === "expired"
  const scope = userScopeStr?.split(" ") || []
  const scopeObj = {
    readOnly: false,
    add: false,
    read: false,
    update: false,
    delete: false
  }
  if (isExpired) {
    return { ...scopeObj, readOnly: true }
  }
  const isAstrictScopePresent = scope.findIndex((a) => a === "*")
  if (isAstrictScopePresent !== -1 || scope.includes(`${str}.*`)) {
    scopeObj["add"] = true
    scopeObj["read"] = true
    scopeObj["update"] = true
    scopeObj["delete"] = true
    return { ...scopeObj }
  }

  if (scope.includes(`${str}.write`)) {
    scopeObj["read"] = true
    scopeObj["add"] = true
    scopeObj["update"] = true
  }

  if (
    scope.includes(`${str}.add`) ||
    scope.includes(`${str}.create`) ||
    scope.includes(`${str}.accept`) ||
    scope.includes(`${str}.generate`)
  ) {
    scopeObj["read"] = true
    scopeObj["add"] = true
  }

  if (scope.includes(`${str}.update`) || scope.includes(`${str}.edit`)) {
    scopeObj["read"] = true
    scopeObj["update"] = true
  }

  if (
    scope.includes(`${str}.read`) ||
    scope.includes(`${str}.get`) ||
    scope.includes(`${str}.search`)
  ) {
    scopeObj["read"] = true
  }

  if (scope.includes(`${str}.delete`)) {
    scopeObj["read"] = true
    scopeObj["delete"] = true
  }

  if (
    !scopeObj["update"] &&
    !scopeObj["add"] &&
    !scopeObj["delete"] &&
    scopeObj["read"]
  ) {
    scopeObj["readOnly"] = true
  }

  return { ...scopeObj }

  // Object.keys(scopeObj).filter(key => scopeObj[key] == false).length == 0 ? scopeObj['readOnly'] = true : null;
  // return scopeObj;
}

/**
 * To check if superadmin or not
 * @returns
 */
export const isSuperadmin = () =>
  (coreStore.getState()?.userInfo?.scope?.split(" ") || [])?.findIndex(
    (a) => a === "*"
  ) !== -1 || APP_TENANT.includes("superadmin")

/**
 * To store date selection list
 */
export const DATE_SELECTION_LIST = [
  {
    text: "Last Hour",
    value: "1H"
  },
  {
    text: "Last 24 Hours",
    value: "1"
  },
  {
    text: "7 Days",
    value: "7"
  },
  {
    text: "14 Days",
    value: "14"
  },
  {
    text: "30 Days",
    value: "30"
  },
  {
    text: "45 Days",
    value: "45"
  },
  {
    text: "60 Days",
    value: "60"
  },
  {
    text: "Custom Range",
    value: "range"
  }
]

export const BOOTSTRAP_URL = `${window.location.origin}/assets/css/bootstrap.min.css`

/**
 * Handles pagination offset calculation for both dynamic no of deletes and dynamic pagination limit.
 * @param totalRec
 * @param limit
 * @param deletedRec
 * @returns
 */
export const calcPaginationOffset = (totalRec, limit, deletedRec) => {
  const totalRecords = totalRec - deletedRec
  let newOffset = 0
  if (totalRecords > limit) {
    let index = Math.floor(totalRecords / limit)
    while (index > 0) {
      newOffset = newOffset + limit
      index--
    }
  }
  return newOffset
}

export const COLORS = {
  INPUT_FIELD_EMPTY: "#3D4E5C",
  INPUT_FIELD_NOT_EMPTY: "#00AF9A"
}
