import {
  NeoComponents,
  Header,
  Container,
  validate,
  validateAllFormFields,
  isSuperadmin
} from "@forcepoint/platform-utilityui"
import { coreStore } from "@forcepoint/platform-coreui"
import * as singleSpa from "single-spa"
import { useEffect, useState } from "react"
import {
  EDIT_PROFILE_CONFIG,
  EditUserReqObj,
  UserProfileForm
} from "../../userprofile.model"
import { useAppDispatch } from "../../../../common/state/app.state"
import userProfileService from "../../service/userprofile.service"
import {
  getUserById,
  getTotpStatus
} from "../../state/userprofile.selector"
import { CODE_LIST, INIT_USER_FORM_STATE, TIMEZONE_LIST } from "../../userprofile.constant"
import AccountInfo from "./AccountInfo"
import CountryInfo from "./CountryInfo"
import EditProfileFooter from "./EditProfileFooter"

const EditUserProfile = () => {
  const currentState = coreStore.getState()
  const isEmulated = sessionStorage.getItem("isEmulated")
  const userId =
    isEmulated === null
      ? currentState?.userInfo?.sub
      : JSON.parse(sessionStorage.getItem("oldUserInfo"))?.sub
  const dispatchAction = useAppDispatch()
  const userById = getUserById()
  const totpStatus = getTotpStatus(userId)
  const configureScope = currentState.userInfo?.scope?.includes("idm.totp.ui")

  /**
   * To store user form object
   */
  const [userFormObj, setUserFormObj] = useState<UserProfileForm>(INIT_USER_FORM_STATE)

  /**
   * To store user form errors
   */
  const [userFormErrors, setUserFormErrors] = useState<any>()

  /**
   * To store valid form
   */
  const [isValidForm, setIsValidForm] = useState<boolean>(false)

  /**
   * To store selected timezone
   */
  const [selectedTimezone, setSelectedTimezone] = useState<any>()

  /**
   * To store initial code
   */
  const [countryCode, setCountryCode] = useState({ text: "", value: "" })

  /**
   * To get country code error
   */
  const [countyCodeError, setCountyCodeError] = useState(false)

  useEffect(() => {
    const activationPendingDiv = document.getElementById("activation-pending")
    if (activationPendingDiv) {
      activationPendingDiv.style.display = "none"
    }

    if (userId) {
      dispatchAction(userProfileService.getUserById({ id: userId }))
      if (!isSuperadmin() && configureScope) {
        dispatchAction(userProfileService.getTOTPStatus({ id: userId }))
      }
    }
  }, [userId])

  /**
   * USer by id Obj
   */
  useEffect(() => {
    if (userById?.value && userById.actionStatus === "success") {
      const [countryCode, mobile] = userById?.value?.mobile?.split(" ") || ["", ""]
      const tempUserFormObj: UserProfileForm = {
        firstName: { value: userById.value.firstName, touched: false },
        lastName: { value: userById.value.lastName, touched: false },
        secondaryEmail: { value: userById.value.secondaryEmail, touched: false },
        countryCode: { value: countryCode?.trim() || "", touched: false },
        mobile: { value: mobile?.trim() || "", touched: false },
        netBiosDomain: { value: userById.value.netbiosDomain, touched: false },
        customAttr1: { value: userById.value.customAttribute1, touched: false },
        customAttr2: { value: userById.value.customAttribute2, touched: false },
        SAMAccountName: { value: userById.value.samAccountName, touched: false },
        userPrincipalName: { value: userById.value.userPrincipalName, touched: false },
        timezone: { value: userById.value.timezone, touched: false },
        objectGUID: { value: userById.value.objectGUID, touched: false }
      }
      setUserFormObj(tempUserFormObj)
      const timezoneValue = TIMEZONE_LIST.find(x => x.utc === userById.value.timezone)
      setSelectedTimezone(timezoneValue)

      const cntCode = userById.value?.mobile?.split(" ")[0]
      if (cntCode) {
        const val = CODE_LIST.find((code) => code.value == cntCode)
        if (val) setCountryCode({ text: val.text, value: val.value })
      } else {
        setCountryCode({ text: "", value: "" })
      }

      validateForm(tempUserFormObj)
    }
  }, [userById])

  useEffect(() => {
    return () => {
      resetForm()
      sessionStorage.removeItem("editProfilePrevUrl")
    }
  }, [])

  /**
   * Reset form to initial state
   */
  const resetForm = () => {
    setUserFormObj(INIT_USER_FORM_STATE)
    setUserFormErrors({})
    setIsValidForm(false)
    setSelectedTimezone(null)
    setCountryCode({ text: "", value: "" })
    setCountyCodeError(false)
  }

  /**
   * Update form details
   * @param key
   * @param val
   */
  const updateDetails = (key, val) => {
    const tempUserFormObj = { ...userFormObj }
    tempUserFormObj[key].value = typeof val === "string" ? val?.trimStart() : val
    tempUserFormObj[key].touched = true
    setUserFormObj(tempUserFormObj)
    validateForm(tempUserFormObj)
  }

  /**
   * Update on touched
   * @param key
   */
  const updateOnTouched = (key) => {
    const tempUserFormObj = { ...userFormObj }
    tempUserFormObj[key].touched = true
    setUserFormObj(tempUserFormObj)
    validateForm(tempUserFormObj)
  }

  /**
   * To validate form
   */
  const validateForm = (tempUserFormObj) => {
    const tempUserFormErrors = validate(tempUserFormObj, EDIT_PROFILE_CONFIG)
    setIsValidForm(tempUserFormErrors.isValidForm)
    setUserFormErrors(tempUserFormErrors.errors)
  }

  /**
   * Action btn call back method
   * @param type
   */
  const actionBtnCallback = (type) => {
    if (type === "configureMFA" || type === "reset") {
      singleSpa.navigateToUrl(`/editprofile/configureMFA`)
    }
  }

  /**
   * Submit the form
   */
  const handleSubmit = () => {
    if (userFormObj?.mobile.value?.trim() !== "" && userFormObj.countryCode.value?.trim() === "") {
      setCountyCodeError(true)
      return false
    }

    const tempUserFormErrors = validateAllFormFields(userFormObj, EDIT_PROFILE_CONFIG)
    setUserFormErrors(tempUserFormErrors.errors)
    setIsValidForm(tempUserFormErrors.isValidForm)

    if (!tempUserFormErrors.isValidForm) return

    const userReqObj: EditUserReqObj = {
      id: userById.value.id,
      firstName: userFormObj.firstName.value,
      lastName: userFormObj.lastName.value,
      secondaryEmail: userFormObj.secondaryEmail.value,
      mobile: `${userFormObj?.countryCode.value} ${userFormObj?.mobile.value}`?.trim(),
      customAttribute1: userFormObj.customAttr1.value,
      customAttribute2: userFormObj.customAttr2.value,
      netbiosDomain: userFormObj.netBiosDomain.value,
      objectGUID: userFormObj.objectGUID.value,
      samAccountName: userFormObj.SAMAccountName.value,
      timezoneId: selectedTimezone?.value,
      userPrincipalName: userFormObj.userPrincipalName.value
    }

    dispatchAction(userProfileService.editUser(userReqObj))
  }

  return (
    <div className="app-section">
      <div className="main-container">
        <div style={{ flex: 1 }}>
          <Header
            pageTitle="Edit Profile"
            actionCallback={actionBtnCallback}
            actionList={
              totpStatus?.actionStatus === "success" && configureScope
                ? [{ type: "secondary", displayName: totpStatus?.value ? "Reset MFA" : "Configure MFA", key: totpStatus?.value ? "reset" : "configureMFA" }]
                : []
            }
          />
        </div>
      </div>
      <Container style={{ height: "calc(100% - 62px)", overflow: "auto" }}>
        <NeoComponents.NeoExpandableContainer>
          <NeoComponents.NeoCenterFlex style={{ width: "100%", height: "100%", flexGrow: 1 }}>
            <form className="common-form" noValidate={true}>
              <div style={{ width: "60%" }}>
                <AccountInfo userFormObj={userFormObj} userFormErrors={userFormErrors} updateDetails={updateDetails} updateOnTouched={updateOnTouched} />
                <CountryInfo userFormObj={userFormObj} userFormErrors={userFormErrors} updateDetails={updateDetails} updateOnTouched={updateOnTouched} selectedTimezone={selectedTimezone} setSelectedTimezone={setSelectedTimezone} countryCode={countryCode} setCountryCode={setCountryCode} countyCodeError={countyCodeError} setCountyCodeError={setCountyCodeError} />
              </div>
              <EditProfileFooter isValidForm={isValidForm} handleSubmit={handleSubmit} />
            </form>
          </NeoComponents.NeoCenterFlex>
        </NeoComponents.NeoExpandableContainer>
      </Container>
    </div>
  )
}

export default EditUserProfile
