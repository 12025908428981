import {
    NeoComponents,
    isEmpty
} from "@forcepoint/platform-utilityui"
import { navigateToUrl } from "../../../utility/header.utility";

const SettingsActions = ({ settingsMenuList }) => {

    return !isEmpty(settingsMenuList) && (
        <li>
            <a
                id="settings_link"
                onKeyDown={() => { }}
                onClick={(e) => {
                    e.preventDefault()
                    navigateToUrl(settingsMenuList[0].url)
                }}
            >
                <NeoComponents.NeoIcon
                    name="settings-outline"
                    size={22}
                    data-always="true"
                    data-dark-tip="Settings"
                />
            </a>
        </li>
    )
};

export default SettingsActions;