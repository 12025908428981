import {
    NeoComponents
} from "@forcepoint/platform-utilityui"

const AccountInfo = ({userFormObj, userFormErrors, updateDetails, updateOnTouched}) => {
    return (
        <>
            <div className="hideInputMsg edit-input">
                <NeoComponents.NeoInput
                    {...{
                        id: "first_name",
                        placeholder: "Enter First Name",
                        containerStyle: { color: "#29343D", width: "90%" },
                        type: "text",
                        disabled: true,
                        label: "First Name:*",
                        value: userFormObj?.firstName?.value,
                        showRequiredLabelIcon: true,
                        errorMessage: userFormErrors?.firstName,
                        tabIndex: 1
                    }}
                />
            </div>
            <div className="hideInputMsg edit-input">
                <NeoComponents.NeoInput
                    {...{
                        id: "last_name",
                        placeholder: "Enter Last Name",
                        containerStyle: { color: "#29343D", width: "90%" },
                        type: "text",
                        tabIndex: 2,
                        label: "Last Name:*",
                        value: userFormObj?.lastName?.value,
                        showRequiredLabelIcon: true,
                        errorMessage: userFormErrors?.lastName,
                        onChange: (event) =>
                            updateDetails("lastName", event.value),
                        onBlur: () => updateOnTouched("lastName")
                    }}
                />
            </div>
            <div className="hideInputMsg edit-input">
                <NeoComponents.NeoInput
                    {...{
                        id: "secondary_email",
                        placeholder: "Enter Secondary Email",
                        containerStyle: { color: "#29343D", width: "90%" },
                        type: "email",
                        label: "Secondary Email:",
                        tabIndex: 3,
                        value: userFormObj?.secondaryEmail?.value,
                        showRequiredLabelIcon: true,
                        errorMessage: userFormErrors?.secondaryEmail,
                        onChange: (event) =>
                            updateDetails("secondaryEmail", event.value),
                        onBlur: () => updateOnTouched("secondaryEmail")
                    }}
                />
            </div>
            <div className="hideInputMsg edit-input">
                <NeoComponents.NeoInput
                    {...{
                        id: "net_bios_domain",
                        placeholder: "Enter NetBios Domain",
                        containerStyle: { color: "#29343D", width: "90%" },
                        type: "text",
                        label: "NetBios Domain:",
                        tabIndex: 3,
                        value: userFormObj?.netBiosDomain?.value,
                        showRequiredLabelIcon: true,
                        errorMessage: userFormErrors?.netBiosDomain,
                        onChange: (event) =>
                            updateDetails("netBiosDomain", event.value),
                        onBlur: () => updateOnTouched("netBiosDomain")
                    }}
                />
            </div>
            <div className="hideInputMsg edit-input">
                <NeoComponents.NeoInput
                    {...{
                        id: "sam_account_name",
                        placeholder: "Enter SAMAccountName",
                        containerStyle: { color: "#29343D", width: "90%" },
                        type: "text",
                        label: "SAMAccountName:",
                        tabIndex: 3,
                        value: userFormObj?.SAMAccountName?.value,
                        showRequiredLabelIcon: true,
                        errorMessage: userFormErrors?.SAMAccountName,
                        onChange: (event) =>
                            updateDetails("SAMAccountName", event.value),
                        onBlur: () => updateOnTouched("SAMAccountName")
                    }}
                />
            </div>
            <div className="hideInputMsg edit-input">
                <NeoComponents.NeoInput
                    {...{
                        id: "user_principal_name",
                        placeholder: "Enter User Principal Name",
                        containerStyle: { color: "#29343D", width: "90%" },
                        type: "text",
                        label: "User Principal Name:",
                        tabIndex: 3,
                        value: userFormObj?.userPrincipalName?.value,
                        showRequiredLabelIcon: true,
                        errorMessage: userFormErrors?.userPrincipalName,
                        onChange: (event) =>
                            updateDetails("userPrincipalName", event.value),
                        onBlur: () => updateOnTouched("userPrincipalName")
                    }}
                />
            </div>
        </>
    );
};

export default AccountInfo;