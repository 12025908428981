import {
  Container,
  NeoComponents,
  CardLoader,
  StyleComponents
} from "@forcepoint/platform-utilityui"
import { useAppDispatch } from "../../../../common/state/app.state"
import { useEffect, useState } from "react"
import userProfileService from "../../service/userprofile.service"
import QRCode from "qrcode"
import {
  getActionStatus,
  getDuoUserConfig,
  getDuoStatus
} from "../../state/userprofile.selector"
import { ActionState } from "../../state/userprofile.state"
import OTPInputBox from "./OTPInputBox"
import { isValidOTPDigit } from "../../utility/userprofile.utility"
import { initPasscodeVal } from "../../userprofile.constant"

/**
 * DuoAuthenticator component that handles Duo 2FA setup and verification.
 */
const DuoAuthenticator = ({ userId }) => {
  const dispatchApi = useAppDispatch()
  const enableResetBtn = true
  const duoStatus = getDuoStatus(userId)
  const duoConfig = getDuoUserConfig()
  const actionStatus = getActionStatus()
  
  const [duoQRUrl, setDuoQRUrl] = useState("")
  const [showDuoSetup, setShowDuoSetup] = useState(false)
  const [duoPasscode, setDuoPasscode] = useState(structuredClone(initPasscodeVal))

  /**
   * Fetch Duo status when the component mounts and reset it on unmount.
   */
  useEffect(() => {
    dispatchApi(userProfileService.getDuoStatus({ id: userId }))
    return () => {
      dispatchApi(userProfileService.resetDuoStatus(userId))
    }
  }, [])

  /**
   * When Duo configuration is updated, show Duo setup screen and generate QR code.
   */
  useEffect(() => {
    if (duoConfig?.value?.activationBarcode) {
      setShowDuoSetup(true)
      generateQRcode(duoConfig.value.activationBarcode)
    }
  }, [duoConfig])

  /**
   * Handles action status changes (e.g., reset or verify Duo user).
   * Based on the action result, update the UI accordingly.
   */
  useEffect(() => {
    const { RESET_DUO_USER_CONFIG, VERIFY_DUO_USER } = ActionState

    if (actionStatus[RESET_DUO_USER_CONFIG]?.actionStatus === "success") {
      setShowDuoSetup(false)
      dispatchApi(userProfileService.getDuoStatus({ id: userId }))
      dispatchApi(userProfileService.resetActionStatus(RESET_DUO_USER_CONFIG))
    }

    if (actionStatus[VERIFY_DUO_USER]?.actionStatus === "success") {
      const verifyStatus = actionStatus[VERIFY_DUO_USER]?.value?.status
      if (verifyStatus) {
        dispatchApi(userProfileService.getDuoStatus({ id: userId }))
      } else {
        setDuoPasscode(structuredClone(initPasscodeVal))
      }
      dispatchApi(userProfileService.resetActionStatus(VERIFY_DUO_USER))
    }
  }, [actionStatus, userId])

  /**
   * Generate QR code using the Duo activation barcode.
   * @param {string} str The barcode string to generate the QR code.
   */
  const generateQRcode = (str) => {
    QRCode.toDataURL(str, (err, url) => err ? console.error(err) : setDuoQRUrl(url))
  }

  /**
   * Fetch the Duo user configuration.
   */
  const duoSetup = () => dispatchApi(userProfileService.getDuoUserConfig())

  /**
   * Verify the Duo user by submitting the OTP passcode.
   * Verifies only if all digits in the passcode are valid.
   */
  const verifyDuoUser = () => {
    if (duoPasscode.every(isValidOTPDigit)) {
      dispatchApi(userProfileService.verifyDuoUser({ passcode: duoPasscode.join("") }))
    }
  }

  /**
   * Reset the Duo user configuration.
   */
  const resetDuoConfig = () => {
    if (enableResetBtn) {
      dispatchApi(userProfileService.resetDuoUserConfig({ id: userId }))
    }
  }

  /**
   * Renders the Duo setup section including QR code and OTP input field.
   * This is displayed when Duo setup is in progress.
   */
  const renderDuoSetupSection = () => {
     if (showDuoSetup && !duoStatus.value && duoStatus.actionStatus === "success" && duoConfig.value.activationBarcode) {
      return (
        <>
          <div className="qr-text-center">
            <p className="scan-text">
              Scan the QR code using the Duo Authenticator App on your Android or iOS device.
            </p>
            <img height={"170px"} width={"auto"} src={duoQRUrl} alt="QR Code" />
          </div>
          <div className="inputs otp-container">
            <OTPInputBox passcode={duoPasscode} setPassCode={setDuoPasscode} />
            <label htmlFor="otp-box-0">Verification code</label>
          </div>
        </>
      )
     }

     if (!showDuoSetup && !duoStatus.value && duoStatus.actionStatus === "success") {
      return (
        <div className="start-duo-btn">
          <NeoComponents.NeoPrimaryButton
            isLoading={duoConfig.actionStatus === "pending"}
            onClick={duoSetup}
          >
            Start Duo User Setup
          </NeoComponents.NeoPrimaryButton>
        </div>
      )
     }

    return null
  }

  /**
   * Renders action buttons such as "Verify" or "Reset" based on the current state.
   */
  const renderActionButtons = () => {
     if (showDuoSetup && !duoStatus.value && duoStatus.actionStatus === "success") {
      return (
        <div className="verify-duo-btn">
          <NeoComponents.NeoPrimaryButton
            isDisabled={!duoPasscode.every(isValidOTPDigit)}
            isLoading={duoConfig.actionStatus === "pending" || actionStatus[ActionState.VERIFY_DUO_USER]?.actionStatus === "pending"}
            onClick={verifyDuoUser}
          >
            Verify
          </NeoComponents.NeoPrimaryButton>
        </div>
      )
     }

    if (duoStatus.value && duoStatus.actionStatus === "success" && enableResetBtn) {
      return (
        <div className="duo-configured-container">
          <div className="duo-configured">
            <NeoComponents.NeoIcon fill="green" name="success_circle" size={12} />
            <span className="duo-configured-text">Duo User configured</span>
          </div>
          <NeoComponents.NeoPrimaryButton
            onClick={resetDuoConfig}
            isLoading={actionStatus[ActionState.RESET_DUO_USER_CONFIG]?.actionStatus === "pending"}
          >
            Reset
          </NeoComponents.NeoPrimaryButton>
        </div>
      )
    }

    return null
  }

  return (
    <Container>
      <NeoComponents.NeoExpandableContainer>
        <NeoComponents.NeoCenterFlex style={{ width: "100%", height: "100%", flexGrow: 1 }}>
          <form className="common-form" noValidate={true}>
            <StyleComponents.Title className="duo-title">Duo User Setup</StyleComponents.Title>

            <div style={{ width: "100%" }}>
              {duoStatus.actionStatus === "pending" && <CardLoader />}

              {renderDuoSetupSection()}
              {renderActionButtons()}
            </div>
          </form>
        </NeoComponents.NeoCenterFlex>
      </NeoComponents.NeoExpandableContainer>
    </Container>
  )
}

export default DuoAuthenticator
