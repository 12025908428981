import { useCallback, useMemo } from 'react';
import { isEmpty } from "@forcepoint/platform-utilityui"
import { navigateToUrl } from '../../utility/header.utility';
import { APP_URL } from "@forcepoint/platform-coreui"
/**
 * To print switcher menus
 * @param menuName
 * @param menuList
 * @returns
 */
const SwitcherMenus = ({ key, menuName, menuList, activeApp, settingsSidebar, setCurrentDropdown }) => {
    const handleClick = useCallback(
        (a) => {
            const applicationUrl = APP_URL.endsWith(".eu") ? a.iframeURL : a.applicationURL;

            let url: string;
            if (!a.isSPA && !a.openInIframe) {
                url = applicationUrl;
            } else if (!isEmpty(a.menus)) {
                url = a.menus[0].url;
            } else {
                url = applicationUrl;
            }

            if (a.isSPA || a.openInIframe) {
                navigateToUrl(url);
            } else {
                window.open(url, a.openInNewTab ? "_blank" : "_self");
            }

            setCurrentDropdown(null); // Close dropdown after action
        },
        [] // No dependencies
    );

    const updatedMenuList = useMemo(() => {
        return menuList?.filter(
            (a) => !(activeApp?.applicationCode === a?.applicationCode && !settingsSidebar)
        );
    }, [menuList, activeApp, settingsSidebar]); // Depend on menuList so it recalculates when menuList changes

    // If the updated menuList is empty, return null
    if (isEmpty(updatedMenuList)) return null;

    return (
        <>
            {menuName !== "none" && <li key={key} className="head-li">{menuName}</li>}

            {updatedMenuList.map((a) => (
                <li key={a.applicationName}>
                    <a
                        id={`${a?.applicationCode}_link`}
                        className="w-100"
                        onClick={() => handleClick(a)}
                        onKeyDown={() => { }}
                    >
                        <span className="shell-app-list-acronym">{a?.applicationCode}</span>
                        {a.applicationName}
                    </a>
                </li>
            ))}
        </>
    );
};
export default SwitcherMenus