import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { APICONSTANTS } from "../../../common/APIConstants"
import { ActionState } from "../state/userprofile.state"
import {
  toFormAPIPayload,
  toMakeAPIRequest,
  showToastr
} from "@forcepoint/platform-utilityui"
import { coreStore } from "@forcepoint/platform-coreui"
import { EditUserReqObj, GetUser } from "../userprofile.model"
import { getAppUrl } from "@forcepoint/platform-utilityui"

const getAppMessages = () => {
  return coreStore.getState()?.applicationMessages
}

const userProfileService = {
  /**
   * To get user by id
   */
  getUserById: createAsyncThunk(
    ActionState.GET_USER_BY_ID,
    async (reqObj: GetUser) => {
      try {
        const options = toFormAPIPayload(
          APICONSTANTS.USERS.GET_USER_BY_ID,
          getAppUrl("idm")
        )
        options.url = options.url.replace("{id}", reqObj.id)
        const optionsData = {
          ...options,
          data: {},
          params: {}
        }
        const response = await toMakeAPIRequest(optionsData)
        return Promise.resolve(response)
      } catch (error) {
        const toastConfig = {
          type: "error",
          message: getAppMessages().messages.user.ERROR_GET_USER_BY_ID
        }
        showToastr(toastConfig)
        return Promise.reject(error)
      }
    }
  ),

  /**
   * To get DUO Status
   */
  getDuoStatus: createAsyncThunk(
    ActionState.GET_DUO_STATUS,
    async (req: any) => {
      // returning dummy response since apis are not ready
      return Promise.resolve({ value: false, actionStatus: "success" })
    }
  ),

  /**
   * To get TOTP Status
   */
  getTOTPStatus: createAsyncThunk(
    ActionState.GET_TOTP_STATUS,
    async (req: any) => {
      try {
        const options = toFormAPIPayload(
          APICONSTANTS.USERPROFILE.GET_TOTP_STATUS,
          getAppUrl("idm")
        )
        const reqObj = {
          ...options,
          params: {}
        }
        reqObj.url = options.url.replace("{id}", req.id)
        const response = await toMakeAPIRequest(reqObj)

        return Promise.resolve(response)
      } catch (error) {
        const toastConfig = {
          type: "error",
          message: getAppMessages().messages.editprofile.ERROR_GET_TOTP_STATUS
        }
        showToastr(toastConfig)
        return Promise.reject(error)
      }
    }
  ),

  /**
   * To get TOTP Config
   */
  getDuoUserConfig: createAsyncThunk(
    ActionState.GET_DUO_USER_CONFIG,
    async () => {
      try {
        const options = toFormAPIPayload(
          APICONSTANTS.USERPROFILE.GET_DUO_USER_CONFIG,
          getAppUrl("idm")
        )
        const reqObj = {
          ...options,
          params: {}
        }
        const response = await toMakeAPIRequest(reqObj)
        return Promise.resolve(response)
      } catch (error) {
        const toastConfig = {
          type: "error",
          message: getAppMessages().exception.default.DEFAULT_ERROR_MSG
        }
        if (error.status && error.status !== 201) {
          toastConfig.message =
            getAppMessages().messages.editprofile.ERROR_GET_TOTP_CONFIG
          if (
            error.status === 400 &&
            error?.data?.AdditionalInfo?.user === "already configured"
          ) {
            toastConfig.type = "error"
            toastConfig.message =
              getAppMessages().messages.editprofile.ERROR_DUO_USER_ALREADY_CONFIGERED
          }

          showToastr(toastConfig)
        }
        return Promise.reject(error)
      }
    }
  ),

  /**
   * To reset DUO User Config
   */
  resetDuoUserConfig: createAsyncThunk(
    ActionState.RESET_DUO_USER_CONFIG,
    async (req: any) => {
      try {
        const options = toFormAPIPayload(
          APICONSTANTS.USERPROFILE.RESET_DUO_USER_CONFIG,
          getAppUrl("idm")
        )
        const reqObj = {
          ...options,
          params: {}
        }
        reqObj.url = options.url.replace("{id}", req.id)
        const response = await toMakeAPIRequest(reqObj)
        const toastConfig = {
          type: "success",
          message:
            getAppMessages().messages.editprofile.SUCCESS_RESET_DUO_USER_CONFIG
        }
        showToastr(toastConfig)
        return Promise.resolve(response)
      } catch (error) {
        const toastConfig = {
          type: "error",
          message:
            getAppMessages().messages.editprofile.ERROR_RESET_DUO_USER_CONFIG
        }
        showToastr(toastConfig)
        return Promise.reject(error)
      }
    }
  ),

  /**
   * To get TOTP Config
   */
  getTOTPConfig: createAsyncThunk(ActionState.GET_TOTP_CONFIG, async () => {
    try {
      const options = toFormAPIPayload(
        APICONSTANTS.USERPROFILE.GET_TOTP_CONFIG,
        getAppUrl("idm")
      )
      const reqObj = {
        ...options,
        params: {}
      }
      const response = await toMakeAPIRequest(reqObj)
      return Promise.resolve(response)
    } catch (error) {
      if (error.status && error.status !== 201) {
        const toastConfig = {
          type: "error",
          message: getAppMessages().messages.editprofile.ERROR_GET_TOTP_CONFIG
        }
        if (
          error.status === 400 &&
          error?.data?.AdditionalInfo?.user === "already configured"
        ) {
          toastConfig.type = "error"
          toastConfig.message =
            getAppMessages().messages.editprofile.ERROR_TOTP_ALREADY_CONFIGED
        }
        showToastr(toastConfig)
      }
      return Promise.reject(error)
    }
  }),

  /**
   * To reset TOTP Config
   */
  resetTOTPConfig: createAsyncThunk(
    ActionState.RESET_TOTP_CONFIG,
    async (req: any) => {
      try {
        const options = toFormAPIPayload(
          APICONSTANTS.USERPROFILE.RESET_TOTP_CONFIG,
          getAppUrl("idm")
        )
        const reqObj = {
          ...options,
          params: {}
        }
        reqObj.url = options.url.replace("{id}", req.id)
        const response = await toMakeAPIRequest(reqObj)
        const toastConfig = {
          type: "success",
          message:
            getAppMessages().messages.editprofile.SUCCESS_RESET_TOTP_CONFIG
        }
        showToastr(toastConfig)
        return Promise.resolve(response)
      } catch (error) {
        const toastConfig = {
          type: "error",
          message: getAppMessages().messages.editprofile.ERROR_RESET_TOTP_CONFIG
        }
        showToastr(toastConfig)
        return Promise.reject(error)
      }
    }
  ),

  /**
   * To verify TOTP
   */
  verifyTOTP: createAsyncThunk(ActionState.VERIFY_TOTP, async (req: any) => {
    try {
      const options = toFormAPIPayload(
        APICONSTANTS.USERPROFILE.VERIFY_TOTP,
        getAppUrl("idm")
      )
      const reqObj = {
        ...options,
        params: {},
        data: {
          passcode: req.passcode
        }
      }
      const response = await toMakeAPIRequest(reqObj)
      if (response?.data?.status) {
        const toastConfig = {
          type: "success",
          message: getAppMessages().messages.editprofile.SUCCESS_VERIFY_TOTP
        }
        showToastr(toastConfig)
      } else {
        const toastConfig = {
          type: "error",
          message: getAppMessages().messages.editprofile.ERROR_VERIFY_TOTP
        }
        showToastr(toastConfig)
      }

      return Promise.resolve(response)
    } catch (error) {
      const toastConfig = {
        type: "error",
        message: getAppMessages().messages.editprofile.ERROR_VERIFY_TOTP
      }
      showToastr(toastConfig)
      return Promise.reject(error)
    }
  }),

  /**
   * To verify DUO User
   */
  verifyDuoUser: createAsyncThunk(
    ActionState.VERIFY_DUO_USER,
    async (req: any) => {
      try {
        const options = toFormAPIPayload(
          APICONSTANTS.USERPROFILE.VERIFY_DUO_USER,
          getAppUrl("idm")
        )
        const reqObj = {
          ...options,
          params: {},
          data: {
            passcode: req.passcode
          }
        }
        const response = await toMakeAPIRequest(reqObj)
        if (response?.data?.status) {
          const toastConfig = {
            type: "success",
            message:
              getAppMessages().messages.editprofile.SUCCESS_VERIFY_DUO_USER
          }
          showToastr(toastConfig)
        } else {
          const toastConfig = {
            type: "error",
            message: getAppMessages().messages.editprofile.ERROR_VERIFY_DUO_USER
          }
          showToastr(toastConfig)
        }

        return Promise.resolve(response)
      } catch (error) {
        const toastConfig = {
          type: "error",
          message: getAppMessages().messages.editprofile.ERROR_VERIFY_DUO_USER
        }
        showToastr(toastConfig)
        return Promise.reject(error)
      }
    }
  ),
  /**
   * To get Duo MFA configuration
   */
  getDuoMFAConfiguration: createAsyncThunk(
    ActionState.GET_DUO_CONFIGURATION,
    async () => {
      try {
        const options = toFormAPIPayload(
          APICONSTANTS.USERPROFILE.GET_DUO_CONFIGURATION,
          getAppUrl("idm")
        )
        const optionsData = {
          ...options,
          params: {}
        }
        const response = await toMakeAPIRequest(optionsData)
        return Promise.resolve(response)
      } catch (error) {
        const toastConfig = {
          type: "error",
          message: getAppMessages().messages.user.ERROR_GET_USER_BY_ID
        }
        showToastr(toastConfig)

        return Promise.reject(error)
      }
    }
  ),
  /**
   * To edit user
   */
  editUser: createAsyncThunk(
    ActionState.EDIT_USER,
    async (reqObj: EditUserReqObj, { dispatch }) => {
      try {
        const options = toFormAPIPayload(
          APICONSTANTS.USERS.EDIT_USER,
          getAppUrl("idm")
        )
        options.url = options.url.replace("{id}", reqObj.id)
        const optionsData = {
          ...options,
          params: {},
          data: reqObj
        }
        const response = await toMakeAPIRequest(optionsData)
        const toastConfig = {
          type: "success",
          message: getAppMessages().messages.user.SUCCESS_EDIT_USER
        }
        showToastr(toastConfig)
        return Promise.resolve(response)
      } catch (error) {
        const toastConfig = {
          type: "error",
          message: getAppMessages().messages.editprofile.ERROR_UPDATE_USER
        }
        showToastr(toastConfig)
        return Promise.reject(error)
      }
    }
  ),
  /**
   * To reset action status
   */
  resetActionStatus: createAction(
    ActionState.RESET_ACTION_STATUS,
    (key: any) => {
      return { payload: key }
    }
  ),
  /**
   * To reset duo status
   */
  resetDuoStatus: createAction(
    ActionState.RESET_DUO_STATUS,
    (userId: string) => {
      return { payload: userId }
    }
  ),
  /**
   * To reset totp status
   */
  resetTotpStatus: createAction(
    ActionState.RESET_TOTP_STATUS,
    (userId: string) => {
      return { payload: userId }
    }
  ),
  /**
   * To reset user state
   */
  resetUserState: createAsyncThunk(
    ActionState.RESET_USER_STATE,
    (type: string) => {
      return { payload: type }
    }
  ),
  /**
   * To reset user state
   */
  resetMFAPageState: createAsyncThunk(
    ActionState.RESET_MFA_PAGE_STATE,
    (type: string) => {
      return { payload: type }
    }
  )
}

export default userProfileService
