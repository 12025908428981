import { isEmpty } from "@forcepoint/platform-utilityui"
import * as singleSpa from "single-spa"
import {
  changePassFormInit,
  errorMessages,
  validatePassword
} from "../header.constant"

export const getApplications = (stateApplications) => {
  const appList = stateApplications?.reduce(
    (a, b) => {
      const shouldAddToSwitcher =
        b.showInSwitcher &&
        ((b.isSPA && !isEmpty(b.menus)) ||
          b.applicationType === "external" ||
          b.openInIframe)

      if (shouldAddToSwitcher) {
        switch (b.menuType) {
          case "application":
            a.applications.push(b)
            break
          case "platform":
            a.platforms.push(b)
            break
          case "none":
            a.coreApps.push(b)
            break
          default:
            break
        }
      }

      return a
    },
    { applications: [], platforms: [], coreApps: [] }
  )

  return appList
}

/**
 * To navigate to SPAs
 * @param url
 */
export const navigateToUrl = (url) => {
  singleSpa.navigateToUrl(url)
}

export const getHelpUrl = (stateData) => {
  // Check if Help is enabled in the UI config and return the corresponding URL
  if (stateData?.activeApp?.modules?.uiConfig?.enableHelp) {
    // Try to get the help URL from activeMenu first, then fall back to activeApp
    return (
      stateData.activeMenu?.supportUrls?.help ||
      stateData.activeApp?.supportUrls?.help ||
      null
    )
  }

  // If help is not enabled, return null
  return null
}

export const getUserInitials = (userInfo) => {
  let username = ""
  let userInitials = ""
  /**
   * To store type of tenant-superadmin/mssp/distributor
   */
  const tenantName = JSON.parse(sessionStorage.getItem("tenantName"))

  // Check if tenantName is provided and assign accordingly
  if (tenantName) {
    username = tenantName?.userName || "" // Fallback to empty string if userName is missing
    userInitials = tenantName?.userInitials || "" // Fallback to empty string if userInitials is missing
  }
  // Otherwise, check if both first_name and last_name are available in userInfo
  else if (userInfo?.first_name && userInfo?.last_name) {
    username = `${userInfo.first_name} ${userInfo.last_name}`
    userInitials = `${userInfo.first_name.charAt(0).toUpperCase()}${userInfo.last_name.charAt(0).toUpperCase()}`
  }
  // If only first_name is available
  else if (userInfo?.first_name) {
    username = userInfo.first_name
    userInitials = userInfo.first_name.slice(0, 2).toUpperCase()
  }
  // If neither first_name nor last_name is available, use username from userInfo
  else {
    username = userInfo?.username || "" // Fallback to empty string if username is not available
    userInitials = username.slice(0, 2).toUpperCase() // Safely calculate initials from username
  }

  // Return as an object to ensure consistent result
  return { username, userInitials }
}

export const getMenuList = (applications) => {
  return [
    { menuName: "none", menuList: applications?.coreApps },
    { menuName: "Applications", menuList: applications?.applications },
    { menuName: "Platform Services", menuList: applications?.platforms }
  ]
}

/**
 * Helper function to validate password fields.
 * Returns validation status and error messages.
 */
export const validatePasswordFields = (
  formObj: any
): { isValid: boolean; errors: Record<string, string> } => {
  const errors: Record<string, string> = {}
  let isValid = true

  // Validate if currentPassword and newPassword are the same
  if (
    formObj.currentPassword.value === formObj.newPassword.value &&
    formObj.currentPassword.value !== ""
  ) {
    isValid = false
    errors["newPassword"] = errorMessages.sameAsCurrentPass.replace(
      "{key}",
      changePassFormInit["newPassword"].text
    )
  }

  // Validate each password-related field
  for (const field in formObj) {
    if (formObj[field].touched) {
      if (formObj[field].value === "") {
        isValid = false
        errors[field] = errorMessages.blankPass.replace(
          "{key}",
          changePassFormInit[field].text
        )
      } else if (
        field !== "confirmPassword" &&
        !validatePassword.test(formObj[field].value)
      ) {
        isValid = false
        errors[field] = errorMessages.invalidPass.replace(
          "{key}",
          changePassFormInit[field].text
        )
      }
    }
  }

  // Validate confirmPassword field if filled
  if (
    formObj.newPassword.value !== formObj.confirmPassword.value &&
    formObj.confirmPassword.value !== ""
  ) {
    isValid = false
    errors["confirmPassword"] = errorMessages.passDoesntMatch
  }

  return { isValid, errors }
}
