import { coreStore } from "@forcepoint/platform-coreui";
import { NeoPopup, NeoComponents } from "@forcepoint/platform-utilityui";
import { useEffect, useState } from "react";
import { UserInfo } from "../../../../../navigation.model";

const ProfileInfoModal = ({ profileInfoModalStatus, setProfileInfoModalStatus }) => {
  const [userinfo, setUserinfo] = useState<UserInfo>(undefined);

  useEffect(() => {
    const stateData = coreStore.getState();
    let userInfoData = { ...stateData.userInfo };

    if (sessionStorage.getItem("isEmulated")) {
      userInfoData = JSON.parse(sessionStorage.getItem("oldUserInfo") || "{}");
    }

    // Map tenantId with additional properties (isClicked and key)
    const updatedTenantId = userInfoData?.ext?.tenantId?.map((tenant, index) => ({
      ...tenant,
      isClicked: false,
      key: index + 1,
    }));

    setUserinfo({
      ...userInfoData,
      ext: {
        ...userInfoData.ext,
        tenantId: updatedTenantId,
      },
    });
  }, []);

  const handleCopy = (tenantId: any, index: number) => {
    navigator.clipboard.writeText(tenantId.id);
    const updatedUserInfo = { ...userinfo };
    updatedUserInfo.ext.tenantId = updatedUserInfo?.ext?.tenantId?.map((tenant, idx) => ({
      ...tenant,
      isClicked: idx === index ? true : tenant.isClicked,
      key: idx + 1,
    }));
    setUserinfo(updatedUserInfo);
  };

  return (
    <NeoPopup
      modalOpenStatus={profileInfoModalStatus}
      modalTitle={"Profile Information"}
      setOpenModalStatus={setProfileInfoModalStatus}
      popupClass={"fone-modal-md"}
    >
      <div className="change-password profile-info-modal">
        <div className="widget-popup-container">
          <div className="padding-container">
            <div className="flex-container">
              <div className="maxw-50">
                <div className="name-label">
                  Name:{" "}
                  <span className="bold-text">
                    {userinfo?.first_name} {userinfo?.last_name}
                  </span>
                </div>
              </div>
              <div className="maxw-50">
                <div>
                  Username:{" "}
                  <span className="bold-text padded-text">
                    {userinfo?.username}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="reference-div">
            <div className="reference-div-header">
              <label className="reference-div-label">Reference IDs</label>
            </div>
            {userinfo?.ext?.tenantId?.map((tenant, index) => (
              <div className="reference-div-userinfo" key={tenant.key}>
                <div className="tenant-hint">{tenant.hint}</div>
                <div className="tenant-id">{tenant.id}</div>
                <div
                  className="copy-button"
                  onClick={() => handleCopy(tenant, index)}
                  onKeyDown={() => {}}
                >
                  {tenant.isClicked ? (
                    <span className="copied-text">Copied</span>
                  ) : (
                    <NeoComponents.NeoIcon
                      data-always="true"
                      data-dark-tip="Click to copy"
                      name="copy1"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </NeoPopup>
  );
};

export default ProfileInfoModal;
