import { ValidationConstant } from "./userprofile.constant"

export interface UserProfileState {
  userById: { value: any; actionStatus: string; showLoader: boolean }
  duoStatus: {}
  duoConfiguration: { value: any; actionStatus: string }
  duoUserConfig: { value: any; actionStatus: string }
  totpStatus: {}
  totpConfig: { value: any; actionStatus: string }
  actionStatus: {}
}
export interface EditUserReqObj {
  id: string
  firstName: string
  lastName: string
  secondaryEmail: string
  netbiosDomain: string
  mobile: string
  objectGUID: string
  samAccountName: string
  userPrincipalName: string
  timezoneId: string
  customAttribute1: string
  customAttribute2: string
}
export interface GetUser {
  id: string
}
export interface UserProfileForm {
  firstName: { value: string; touched: boolean }
  lastName: { value: string; touched: boolean }
  secondaryEmail: { value: string; touched: boolean }
  mobile: { value: string; touched: boolean }
  netBiosDomain?: { value: string; touched: boolean }
  SAMAccountName?: { value: string; touched: boolean }
  userPrincipalName?: { value: string; touched: boolean }
  timezone?: { value: string; touched: boolean }
  objectGUID?: { value: string; touched: boolean }
  customAttr1?: { value: string; touched: boolean }
  customAttr2?: { value: string; touched: boolean }
  countryCode: { value: string; touched: boolean }
}

export const EDIT_PROFILE_CONFIG = {
  lastName: {
    required: { name: "Last Name" },
    maxLength: { name: "Last Name", max: 128 },
    minLength: { name: "Last Name", min: 1 },
    pattern: {
      name: "Last Name",
      regex: ValidationConstant.firstNameLastName_regex
    }
  },
  secondaryEmail: {
    maxLength: { name: "Secondary Email", max: 255 },
    minLength: { name: "Secondary Email", min: 1 },
    pattern: {
      name: "Secondary Email",
      regex: ValidationConstant.email_regex
    }
  }
}
