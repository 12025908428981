import { DefaultDrawerHeader } from "@forcepoint/platform-utilityui"
import { coreStore } from "@forcepoint/platform-coreui"
import * as singleSpa from "single-spa"
import { useAppDispatch } from "../../../../common/state/app.state"
import { useEffect } from "react"
import userProfileService from "../../service/userprofile.service"
import { getDuoConfiguration } from "../../state/userprofile.selector"
import TOTPAuthenticator from "./TOTPAuthenticator"
import DuoAuthenticator from "./DuoAuthenticator"
const ConfigurMFA = () => {
  const dispatchApi = useAppDispatch()

  const appstate = coreStore.getState()
  const userId = appstate?.userInfo?.sub

  const duoConfiguration = getDuoConfiguration()

  /**
   * On back click event
   */
  const onBackClick = () => {
    singleSpa.navigateToUrl(`/editprofile`)
  }
  const resetPage = () => {
    dispatchApi(userProfileService.resetMFAPageState(""))
  }
  /**
   * Component init
   */
  useEffect(() => {
    dispatchApi(userProfileService.getDuoMFAConfiguration())
    return () => {
      resetPage()
    }
  }, [])

  return (
    <div className="users">
      <DefaultDrawerHeader
        title={"Configure Authenticator app"}
        handleCloseDrawer={onBackClick}
      />
      <div
        className="form-align-left common-form"
        style={{ display: "grid", gap: "16px" }}
      >
        <TOTPAuthenticator userId={userId} />
        {duoConfiguration.value &&
          duoConfiguration.actionStatus === "success" ? (
          <DuoAuthenticator userId={userId} />
        ) : null}
      </div>
    </div>
  )
}

export default ConfigurMFA
