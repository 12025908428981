import { useState } from 'react';
import {
    NeoComponents,
    calcScopePermission
} from "@forcepoint/platform-utilityui"
import AlertsDropdown from './AlertsDropdown';

const NotificationAction = ({ stateData, setCurrentDropdown, currentDropdown }) => {
    const activeApp = stateData?.activeApp;
    const userInfo = stateData?.userInfo
    const alertPermissions = userInfo && calcScopePermission("idm.alert");

    /**
    * To store and set alerts count
    */
    const [alertsCount, setAlertsCount] = useState<number>(0)

    return activeApp?.modules?.uiConfig?.enableNotifications &&
        (alertPermissions?.read || alertPermissions?.readOnly) && (
            <li className="shell-dropdown-container notification-dropdown">
                <a
                    id="alert_link"
                    onKeyDown={() => { }}
                    onClick={(e) => {
                        e.preventDefault()
                        setCurrentDropdown("alert")
                    }}
                    className="pos-relative"
                >
                    <NeoComponents.NeoIcon
                        name="bell"
                        size={22}
                        data-always="true"
                        data-dark-tip="Notifications"
                    />
                    {alertsCount > 0 ? (
                        <span className="indicator">{alertsCount}</span>
                    ) : null}
                </a>

                <ul
                    style={{ maxHeight: "70vh", overflowY: "auto" }}
                    className={
                        "shell-dropdown notification-dropdown-menu " +
                        (currentDropdown === "alert" ? "show" : "")
                    }
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 20px"
                        }}
                    >
                        <li>
                            <label className="li-notifications">Notifications</label>
                        </li>
                    </div>

                    <AlertsDropdown
                        userToken={stateData?.userToken}
                        activeApp={activeApp}
                        setAlertsCount={setAlertsCount}
                    />
                </ul>
            </li>
        )
};

export default NotificationAction;