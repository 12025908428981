import { isEmpty } from "@forcepoint/platform-utilityui"

export const blockInvalidChar = (e) => {
  const charCode = e.which ? e.which : e.keyCode
  if (
    charCode > 31 &&
    (charCode < 48 || charCode > 57) &&
    (charCode < 96 || charCode > 105) &&
    charCode !== 39 &&
    charCode !== 37
  ) {
    e.preventDefault()
  }
}

export const isValidOTPDigit = (val: string): boolean =>
  !!val && +val >= 0 && +val <= 9

/**
 * To get mobile values
 * @returns
 */
export function getMobileValue(userFormObj) {
  if (userFormObj?.mobile?.value.includes(" ")) {
    const obj = userFormObj?.mobile?.value.split(" ")
    if (isEmpty(obj[1])) {
      return ""
    }
    return Number(obj[1])
  } else {
    return userFormObj?.mobile?.value
  }
}
