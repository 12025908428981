import {
  Container,
  NeoComponents,
  CardLoader,
  StyleComponents
} from "@forcepoint/platform-utilityui"
import { useAppDispatch } from "../../../../common/state/app.state"
import { useEffect, useState } from "react"
import userProfileService from "../../service/userprofile.service"
import QRCode from "qrcode"
import {
  getActionStatus,
  getTotpConfig,
  getTotpStatus
} from "../../state/userprofile.selector"
import { ActionState } from "../../state/userprofile.state"
import OTPInputBox from "./OTPInputBox"
import { isValidOTPDigit } from "../../utility/userprofile.utility"
import { initPasscodeVal } from "../../userprofile.constant"

const TOTPAuthenticator = ({ userId }) => {
  const dispatchApi = useAppDispatch()

  const enableResetBtn = true
  const totpStatus = getTotpStatus(userId)
  const totpConfig = getTotpConfig()
  const actionStatus = getActionStatus()

  const [totpQRUrl, setTotpQRUrl] = useState("") // Stores the TOTP QR code URL
  const [showTotpSetup, setShowTotpSetup] = useState(false) // Flag to display TOTP setup
  const [totpPasscode, setTotpPasscode] = useState<string[]>(structuredClone(initPasscodeVal))

  /**
   * Effect to handle TOTP configuration setup (QR code generation).
   * This effect triggers whenever the TOTP configuration changes.
   */
  useEffect(() => {
    if (totpConfig?.value?.totpURL) {
      setShowTotpSetup(true)
      generateQRcode(totpConfig.value.totpURL)
    }
  }, [totpConfig])

  /**
   * Effect to handle changes in action status (reset or verify TOTP).
   * Based on the action result, the UI and state are updated.
   */
  useEffect(() => {
    const { RESET_TOTP_CONFIG, VERIFY_TOTP } = ActionState

    // Handle success state for resetting TOTP configuration
    if (actionStatus[RESET_TOTP_CONFIG]?.actionStatus === "success") {
      setShowTotpSetup(false)
      dispatchApi(userProfileService.getTOTPStatus({ id: userId }))
      dispatchApi(userProfileService.resetActionStatus(RESET_TOTP_CONFIG))
    }

    // Handle success state for verifying TOTP
    if (actionStatus[VERIFY_TOTP]?.actionStatus === "success") {
      const verifyStatus = actionStatus[VERIFY_TOTP]?.value?.status
      if (verifyStatus) {
        dispatchApi(userProfileService.getTOTPStatus({ id: userId }))
      } else {
        setTotpPasscode(structuredClone(initPasscodeVal)) // Reset passcode if verification failed
      }
      dispatchApi(userProfileService.resetActionStatus(VERIFY_TOTP))
    }
  }, [actionStatus, userId])

  /**
   * Function to start the TOTP configuration process (retrieving TOTP config).
   */
  const totpSetup = () => {
    dispatchApi(userProfileService.getTOTPConfig())
  }

  /**
   * Function to verify the TOTP code entered by the user.
   * Validates the passcode and triggers the verification API.
   */
  const verifyTotp = () => {
    if (totpPasscode.every(isValidOTPDigit)) {
      dispatchApi(userProfileService.verifyTOTP({
        passcode: totpPasscode.join("")
      }))
    }
    setTotpPasscode(structuredClone(initPasscodeVal)) // Reset the passcode input after verification attempt
  }

  /**
   * Effect to fetch the current TOTP status when the component mounts.
   * Also resets the TOTP status when the component is unmounted.
   */
  useEffect(() => {
    dispatchApi(userProfileService.getTOTPStatus({ id: userId }))
    return () => {
      dispatchApi(userProfileService.resetTotpStatus(userId))
    }
  }, [userId])

  /**
   * Function to generate the QR code for the TOTP configuration.
   * The generated QR code is stored in the state for rendering.
   */
  const generateQRcode = (str) => {
    QRCode.toDataURL(str, (err, url) => {
      setTotpQRUrl(url)
    })
  }

  /**
   * Function to reset the TOTP configuration (used for testing purposes).
   * This function is called when the reset button is clicked.
   */
  const resetTotpConfig = () => {
    if (enableResetBtn) {
      dispatchApi(userProfileService.resetTOTPConfig({ id: userId }))
    }
  }

  /**
   * Renders the TOTP setup section including QR code and OTP input field.
   * This is displayed when TOTP setup is in progress or has not been completed yet.
   */
  const renderTotpSetupSection = () => {
    if (
      showTotpSetup &&
      !totpStatus.value &&
      totpStatus.actionStatus === "success" &&
      totpConfig.value.secretKey &&
      totpConfig.value.totpURL &&
      totpConfig.actionStatus === "success"
    ) {
      return (
        <>
          <div className="qr-text-center">
            <p className="scan-text">
              Scan the QR code using the Google Authenticator App on your Android or iOS device.
            </p>
            <img height={"170px"} width={"auto"} src={totpQRUrl} alt="QR Code" />

            <p className="scan-text">
              If you cannot scan the barcode, enter the following key
            </p>
            <div className="secret-key-code">
              <div className="disabled-div">
                {totpConfig.value.secretKey &&
                  Array(6)
                    .fill("")
                    .map((val, index) =>
                      totpConfig.value.secretKey.slice(index * 4, index * 4 + 4)
                    )
                    .join(" ")}
              </div>
            </div>
          </div>
          {totpConfig.value.secretKey ? (
            <div className="inputs otp-container">
              <OTPInputBox passcode={totpPasscode} setPassCode={setTotpPasscode} />
              <label htmlFor="otp-box-0">Verification code</label>
            </div>
          ) : null}
        </>
      )
    }

    if (!showTotpSetup && !totpStatus.value && totpStatus.actionStatus === "success") {
      return (
        <div className="start-totp-btn">
          <NeoComponents.NeoPrimaryButton
            isLoading={totpConfig.actionStatus === "pending"}
            onClick={totpSetup}
          >
            Start Verification Code Setup
          </NeoComponents.NeoPrimaryButton>
        </div>
      )
    }

    return null
  }

  /**
   * Renders action buttons such as "Verify" or "Reset" based on the current state of TOTP setup.
   */
  const renderActionButtons = () => {
    if (showTotpSetup && !totpStatus.value && totpStatus.actionStatus === "success") {
      return (
        <div className="verify-totp-btn">
          <NeoComponents.NeoPrimaryButton
            isDisabled={!totpPasscode.every(isValidOTPDigit)}
            isLoading={totpConfig.actionStatus === "pending" || actionStatus[ActionState.VERIFY_TOTP]?.actionStatus === "pending"}
            onClick={verifyTotp}
          >
            Verify
          </NeoComponents.NeoPrimaryButton>
        </div>
      )
    }

    if (totpStatus.value && totpStatus.actionStatus === "success" && enableResetBtn) {
      return (
        <div className="totp-configured-container">
          <div className="totp-configured">
            <NeoComponents.NeoIcon fill="green" name="success_circle" size={12} />
            <span className="totp-configured-text">Verification Code Configured</span>
          </div>
          <NeoComponents.NeoPrimaryButton
            onClick={resetTotpConfig}
            isLoading={actionStatus[ActionState.RESET_TOTP_CONFIG]?.actionStatus === "pending"}
          >
            Reset
          </NeoComponents.NeoPrimaryButton>
        </div>
      )
    }

    return null
  }

  return (
    <Container>
      <NeoComponents.NeoExpandableContainer>
        <NeoComponents.NeoCenterFlex
          style={{ width: "100%", height: "100%", flexGrow: 1 }}
        >
          <form className="common-form" noValidate={true}>
            <StyleComponents.Title className="totp-title">
              Verification Code Setup
            </StyleComponents.Title>
            <div style={{ width: "100%" }}>
              {totpStatus.actionStatus === "pending" && <CardLoader />}
              {renderTotpSetupSection()}
              {renderActionButtons()}
            </div>
          </form>
        </NeoComponents.NeoCenterFlex>
      </NeoComponents.NeoExpandableContainer>
    </Container>
  )
}

export default TOTPAuthenticator
