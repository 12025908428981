import React from "react"
import ReactDOMClient from "react-dom/client"
import singleSpaReact from "single-spa-react"
import Root from "./root.component"
import singleSpaCss from "single-spa-css"
const RenderApp = () => {
  return <Root />
}
const lifecycles = singleSpaReact({
  renderType: "createRoot", // Pass this prop in order to correctly identify createRoot
  React,
  ReactDOMClient,
  rootComponent: RenderApp,
  errorBoundary: () => {
    return null
  }
})

/*
 * To store CSS life cycles.
 */
const cssLifeCycles = singleSpaCss({
  cssUrls: [
    {
      href: `${window.location.origin}/assets/css/navigationui.css`,
      shouldUnmount: true
    }
  ],
  timeout: 60000
})
export const bootstrap = [cssLifeCycles.bootstrap, lifecycles.bootstrap]

export const mount = [cssLifeCycles.mount, lifecycles.mount]

export const unmount = [lifecycles.unmount, cssLifeCycles.unmount]
