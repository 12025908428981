import { coreStore } from "@forcepoint/platform-coreui"
import { useState } from "react"
import {
  showToastr,
  NeoComponents,
  NeoPopup,
  getAppUrl
} from "@forcepoint/platform-utilityui"
import { validatePasswordFields } from "../../../utility/header.utility"
import { changePassFormErrorsInit, changePassFormInit, showPassInitialState } from "../../../header.constant"
import { ChangePassFormInit } from "../../../../../navigation.model"

const ChangePasswordPopup = ({ changePasswordPopupStatus, setChangePasswordPopupStatus }) => {
  /**
   * To store app messages
   */
  const appMessages = coreStore.getState()?.applicationMessages

  /**
   * To store state data
   */
  const stateData = coreStore.getState()

  /**
   * To store user token
   */
  const userToken = stateData?.userToken

  /**
   * To store user id
   */
  const userId = stateData?.userInfo?.sub

  /**
   * To store change password form
   */
  const [changePassForm, setChangePassForm] = useState<ChangePassFormInit>(changePassFormInit)

  /**
   * To store is valid form status
   */
  const [isValidForm, setIsValidForm] = useState<any>(false)

  /**
   * To store change password form errors
   */
  const [changePassFormErrors, setChangePassFormErrors] = useState<any>(
    changePassFormErrorsInit
  )

  /**
   * To store show password state
   */
  const [showPassState, setShowPassState] = useState(showPassInitialState)

  /**
   * On click of show password
   * @param key
   */
  const onClickShowPass = (key) => {
    setShowPassState({ ...showPassState, [key]: !showPassState[key] })
  }

  /**
   * Updates the form based on the key-value pair provided.
   * Validates the updated form and sets the state accordingly.
   */
  const updateDetails = (key: keyof any, val: string): void => {
    const updatedForm = { ...changePassForm, [key]: { value: val, touched: true } };

    // Validate the updated form fields
    const { isValid, errors } = validatePasswordFields(updatedForm);

    // Update the state with validation results
    setIsValidForm(isValid);
    setChangePassForm(updatedForm);
    setChangePassFormErrors(errors);
  };

  /**
   * Marks a specific form field as touched and revalidates the form.
   */
  const updateOnTouched = (key: keyof any): void => {
    const updatedForm = { ...changePassForm };
    updatedForm[key].touched = true;

    // Validate the updated form fields
    const { isValid, errors } = validatePasswordFields(updatedForm);

    // Update the state with validation results
    setIsValidForm(isValid);
    setChangePassForm(updatedForm);
    setChangePassFormErrors(errors);
  };


  /**
 * Helper function to show toast notifications
 */
  const showToast = (type: "success" | "error", message: string) => {
    showToastr({
      type,
      message
    });
  };

  /**
   * On Submit
   */
  const submit = () => {
    fetch(`${getAppUrl("idm")}api/users/${userId}/passwords`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`
      },
      body: JSON.stringify({
        oldPassword: changePassForm.currentPassword.value,
        newPassword: changePassForm.newPassword.value
      })
    })
      .then((res) => res.json())
      .then((res) => {
        const { AdditionalInfo } = res;
        if (AdditionalInfo) {
          const { oldPassword, newPassword } = AdditionalInfo;

          // Check if old password is incorrect
          if (oldPassword === "Key_IncorrectOldPassword") {
            showToast("error", appMessages.exception.change_password.Key_IncorrectOldPassword);
            return;
          }

          // Check if new password is the same as the old one
          if (newPassword === "Key_CannotBeSameAsOld") {
            showToast("error", appMessages.exception.change_password.Key_CannotBeSameAsOld);
            return;
          }
        }

        // If no errors, show success message
        showToast("success", appMessages.messages.change_password.SUCCESS_CHANGE_PASSWORD);
        setChangePasswordPopupStatus(false);
      })
      .catch(() => {
        // Handle any unexpected errors
        showToast("error", appMessages.messages.change_password.ERROR_CHANGE_PASSWORD);
      });
  };

  return (
    <div className="popup-modal">
      <NeoPopup
        modalOpenStatus={changePasswordPopupStatus}
        modalTitle={"Change Password"}
        setOpenModalStatus={setChangePasswordPopupStatus}
        popupClass={"fone-modal-sm change-password-popup"}
      >
        <p className="password-modal-text">
          Try using multiple words to create a memorable phrase. Long passwords
          with many words are stronger than short passwords with special
          characters.
        </p>
        <div className="password-policy-info">
          <p> Password should follow these criteria's:</p>
          <ol>
            <li>Minimum 8 characters.</li>
            <li> Maximum 20 characters.</li>
            <li>At least one upper and one lower case letter.</li>
            <li>At least one digit.</li>
            <li>
              At least one special character from the following set. ! @ # $ % &
              ? = [ ] &lt; &gt; &#123; &#125;
            </li>
          </ol>
        </div>

        <div className="change-password">
          <div>
            <form style={{ paddingRight: "1rem" }} noValidate>
              <div style={{ width: "100%" }}>
                <div
                  className="password-container"
                >
                  <NeoComponents.NeoInput
                    {...{
                      id: "current_password",
                      placeholder: "Enter Current Password",
                      containerStyle: { color: "#29343D" },
                      type: showPassState.currentPassword ? "text" : "password",
                      maxLength: 20,
                      minLength: 4,
                      label: "Current Password :*",
                      value: changePassForm?.currentPassword.value,
                      showRequiredLabelIcon: true,
                      errorMessage: changePassFormErrors?.currentPassword,
                      onChange: (event) =>
                        updateDetails("currentPassword", event.value),
                      onBlur: (event) => updateOnTouched("currentPassword"),
                      autoComplete: "new-password",
                      autoFocus: true
                    }}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ margin: "0px 10px" }}>
                      <NeoComponents.NeoIcon
                        fill="#00af9a"
                        name={!showPassState?.currentPassword ? "hidden-password-default" : "show-password-default"}
                        data-always="true"
                        data-margin="2px"
                        data-padding="8px 12px"
                        data-white-space="nowrap"
                        data-word-break="normal"
                        onClick={() => onClickShowPass("currentPassword")}
                        size={16}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  className="password-container"
                >
                  <NeoComponents.NeoInput
                    {...{
                      id: "new_password",
                      placeholder: "Enter New Password",
                      containerStyle: { color: "#29343D" },
                      type: showPassState.newPassword ? "text" : "password",
                      maxLength: 20,
                      minLength: 4,
                      label: "New Password :*",
                      value: changePassForm?.newPassword.value,
                      showRequiredLabelIcon: true,
                      errorMessage: changePassFormErrors?.newPassword,
                      onChange: (event) =>
                        updateDetails("newPassword", event.value),
                      onBlur: (event) => updateOnTouched("newPassword"),
                      autoComplete: "new-password"
                    }}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ margin: "0px 10px" }}>
                      <NeoComponents.NeoIcon
                        fill="#00af9a"
                        name={!showPassState?.newPassword ? "hidden-password-default" : "show-password-default"}
                        data-always="true"
                        data-margin="2px"
                        data-padding="8px 12px"
                        data-white-space="nowrap"
                        data-word-break="normal"
                        onClick={() => onClickShowPass("newPassword")}
                        size={16}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <NeoComponents.NeoInput
                    {...{
                      id: "confirm_password",
                      placeholder: "Confirm New Password",
                      containerStyle: { color: "#29343D" },
                      type: showPassState.confirmPassword ? "text" : "password",
                      maxLength: 20,
                      minLength: 4,
                      label: "Confirm New Password :*",
                      value: changePassForm?.confirmPassword.value,
                      showRequiredLabelIcon: true,
                      errorMessage: changePassFormErrors?.confirmPassword,
                      onChange: (event) =>
                        updateDetails("confirmPassword", event.value),
                      onBlur: (event) => updateOnTouched("confirmPassword"),
                      autoComplete: "new-password"
                    }}
                  />
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="info-tooltip"
                  >
                    <div style={{ margin: "0px 10px" }}>
                      <NeoComponents.NeoIcon
                        fill="#00af9a"
                        name={!showPassState?.confirmPassword ? "hidden-password-default" : "show-password-default"}
                        data-always="true"
                        data-margin="2px"
                        data-padding="8px 12px"
                        data-white-space="nowrap"
                        data-word-break="normal"
                        onClick={() => onClickShowPass("confirmPassword")}
                        size={16}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem"
            }}
          >
            <NeoComponents.NeoSecondaryButton
              id="cp_cancel_btn"
              onClick={() => {
                if (setChangePasswordPopupStatus) {
                  setChangePasswordPopupStatus()
                }
              }}
            >
              Cancel
            </NeoComponents.NeoSecondaryButton>
            <span style={{ marginRight: "1rem" }}></span>
            <NeoComponents.NeoPrimaryButton
              id="cp_update_password_btn"
              isDisabled={!isValidForm}
              onClick={submit}
            >
              Update
            </NeoComponents.NeoPrimaryButton>
          </div>
        </div>
      </NeoPopup>
    </div>
  )
}
export default ChangePasswordPopup
