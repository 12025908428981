import { useState, useEffect } from "react"
import { APP_URL } from "@forcepoint/platform-coreui"
import { NeoComponents, formatDate } from "@forcepoint/platform-utilityui"

export default function AlertsDropdown({
  activeApp,
  userToken,
  setAlertsCount
}) {
  const [alertOptions, setAlertOptions] = useState([])
  /*
    Fetch alerts
    */
  const fetchAlerts = () => {
    fetch(`${APP_URL}/api/alerts/active`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.error) {
          setAlertOptions(res)
          setAlertsCount(res.length)
        }
      })
      .catch((error) => {
        console.log("Some error occured")
      })
  }

  /**
   * To set menus
   */
  useEffect(() => {
    if (userToken && activeApp?.modules?.uiConfig?.enableNotifications) {
      fetchAlerts()
    }
  }, [activeApp])

  const dismiss = (id) => {
    fetch(`${APP_URL}/api/alerts/${id}/dismiss`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.error) {
          fetchAlerts()
        }
      })
      .catch((error) => {
        console.log("Some error occured")
      })
  }

  return alertOptions?.length > 0 ? (
    <>
      {alertOptions?.map((alert) => (
        <li
          key={alert.id}
          style={{
            borderBottom: "1px solid #ccc",
            margin: "2%",
            display: "flex"
          }}
        >
          <div className="div-label">
            <label className="options-label">APP</label>
          </div>
          <div>
            <label className="options-alert-message">
              {alert.message} {alert.count > 1 ? `(${alert.count})` : ""}
            </label>
            <label className="options-alert-updatedAt">
              {formatDate(alert.updatedAt, "DD MMM YYYY hh:mm:ss", "UTC")}
            </label>
          </div>
          <div>
            <NeoComponents.NeoIcon
              data-always
              data-dark-tip="Dismiss"
              fill="black"
              name="close"
              style={{ cursor: "pointer" }}
              onClick={() => dismiss(alert.id)}
              size={10}
            />
          </div>
        </li>
      ))}
    </>
  ) : (
    <>
      <hr className="no-notification-header" />
      <li className="no-notification-content">No notifications available.</li>
    </>
  )
}
