import { APP_TENANT } from "@forcepoint/platform-coreui"
const ViewTenantTitle = () => {
    return sessionStorage.getItem("isEmulated") && (
        <div className="view-context">{`Viewing as ${APP_TENANT.slice(
            0,
            -1
        )}`}</div>
    )
};

export default ViewTenantTitle;