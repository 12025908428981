import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import {
  useNeoWindowSize,
  StyleComponents
} from "@forcepoint/platform-utilityui"
import EditUserProfile from "./components/EditUserProfile/EditUserProfile"
import UserProfileDrawer from "./UserProfileDrawer"

/**
 * Users Container component
 * @param props
 * @returns
 */
const UserProfileContainer = () => {
  /**
   * To store window size
   */
  const windowSize = useNeoWindowSize()

  /**
   * To store location
   */
  const location = useLocation().pathname.split("/")

  /**
   * To store path
   */
  let path
  if (location.includes("configureMFA")) {
    path = "configureMFA"
  }

  /**
   * To store is open status
   */
  const isOpen = useMemo(() => path, [path])

  /**
   * To store user drawer width
   */
  const userDrawerWidth = useMemo(
    () => (windowSize.width < 1500 ? "750px" : "45%"),
    [windowSize]
  )

  /**
   * UI Template
   */
  return (
    <StyleComponents.PageContainer>
      <StyleComponents.MainPage isOpen={isOpen} width={userDrawerWidth}>
        <StyleComponents.Page>
          <EditUserProfile />
        </StyleComponents.Page>
      </StyleComponents.MainPage>
      {isOpen ? (
        <UserProfileDrawer
          isOpen={isOpen}
          userDrawerWidth={userDrawerWidth}
        />
      ) : null}
    </StyleComponents.PageContainer>
  )
}

export default UserProfileContainer
