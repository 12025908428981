import { AnyAction, createSlice } from "@reduxjs/toolkit"
import { AsyncReducers } from "../app.model"

const isRejectedAction = (action: AnyAction) => action.type.endsWith("rejected")
const isFulfilledAction = (action: AnyAction) =>
  action.type.endsWith("fulfilled")
const isPendingAction = (action: AnyAction) => action.type.endsWith("pending")

const getHeaders = (headersData) => {
  const headers = ["x-total-count"]
  return headers.reduce((obj, val) => {
    obj[val] = headersData[val]
    return obj
  }, {})
}

const parseCustomPromiseResponse = (arr) => {
  return (
    arr?.map((item) =>
      item.status === "fulfilled" ? item.value.data : null
    ) || []
  )
}

const asyncAPIReducers = (reducersObj: AsyncReducers) => (builder) => {
  builder.addDefaultCase((state, action: AnyAction) => {
    const { payload, type } = action
    const actionType = type.split("/")[0]

    if (payload?.headers) {
      action.meta.headers = getHeaders(payload.headers)
      action.payload = payload.data
    }

    if (payload?.custom) {
      action.payload = parseCustomPromiseResponse(payload.promise)
    }

    const reducer = reducersObj[actionType]
    if (reducer) {
      if (isFulfilledAction(action) && reducer.success) {
        reducer.success(state, action)
      } else if (isPendingAction(action) && reducer.pending) {
        reducer.pending(state, action)
      } else if (isRejectedAction(action) && reducer.rejected) {
        reducer.rejected(state, action)
      }
    }
  })
}

export const createCustomSlice = ({
  name,
  initialState,
  reducers,
  asyncReducers
}) => {
  return createSlice({
    name,
    initialState,
    reducers,
    extraReducers: asyncAPIReducers(asyncReducers)
  })
}