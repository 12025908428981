import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { coreStore, getEnvionment } from "@forcepoint/platform-coreui";
import {
  getLocalPersistSession,
  getTabCount,
  isTabActive,
  sessionIdleTime,
  sessionLogoutTimer
} from "./sessionMgmt";
import { SessionAlert } from "./components/SessionAlert";
import { isEmpty } from "@forcepoint/platform-utilityui";
import ViewTenantTitle from "./components/ViewTenantTitle";
import ApplicationSwitcher from "./components/ApplicationSwitcher/ApplicationSwitcher";
import PageTitle from "./components/PageTitle";
import DefaultHeader from "./components/DefaultHeader";
import QuickActionButtons from "./components/QuickActionButtons/QuickActionButtons";

export default function Header({ name, applications, activeApp, pageTitle, settingsSidebar }) {
  // ----------------------------------------------------------------------------------------
  // -------------------------------- Component States --------------------------------------
  // ----------------------------------------------------------------------------------------
  /**
   * To store stateData
   */
  const stateData = coreStore.getState();

  /**
   * To set current dropdown
   */
  const [currentDropdown, setCurrentDropdown] = useState();

  /**
   * To store and set session persistance state
   */
  const persistSession = getLocalPersistSession();

  /**
   * To store and set session persistance state
   */
  const [showIdleTimeoutAlert, setShowIdleTimeoutAlert] = useState(false);

  // ----------------------------------------------------------------------------------------
  // -------------------------------- Component effects --------------------------------------
  // ----------------------------------------------------------------------------------------

  /**
   * On Init effect
   */
  useEffect(() => {
    const toggleDropdown = ({ target }) => {
      const nativeElement = document.querySelector(".shell-dropdown.show");
      if (nativeElement && !nativeElement.parentNode.contains(target)) {
        setCurrentDropdown(null);
      }
    };

    document.body.addEventListener("click", toggleDropdown);
    return () => document.body.removeEventListener("click", toggleDropdown);
  }, []);

  /**
   * Current Dropdown effect
   */
  useEffect(() => {
    document.body.classList.toggle("menu-open", !isEmpty(currentDropdown));
  }, [currentDropdown]);

  /**
   * Show inactivity alert
   */
  const sessionInactivity = () => {
    setShowIdleTimeoutAlert(true);
  };

  /**
   * Handle on idle function
   */
  const handleOnIdle = () => {
    const isSessionValid = () => stateData?.userToken && !persistSession && getEnvionment() !== "dev";
    const shouldInactivateSession = () => (getTabCount() > 0 && !isTabActive()) || (!document.hidden && isTabActive());

    if (isSessionValid() && shouldInactivateSession()) {
      sessionInactivity();
    }
  };

  useIdleTimer({
    onIdle: handleOnIdle,
    timeout: Math.max(sessionIdleTime, sessionLogoutTimer) * 60 * 1000
  });

  // ----------------------------------------------------------------------------------------
  // ------------------------------------ HTML Template -------------------------------------
  // ----------------------------------------------------------------------------------------

  return stateData?.userToken ? (
    <nav className="shell-app-header">
      <ViewTenantTitle />
      <div className="shell-app-logo">
        <ApplicationSwitcher
          stateApplications={applications}
          activeApp={activeApp}
          settingsSidebar={settingsSidebar}
          currentDropdown={currentDropdown}
          setCurrentDropdown={setCurrentDropdown}
        />
        <PageTitle name={name} pageTitle={pageTitle} />
      </div>
      <QuickActionButtons
        setCurrentDropdown={setCurrentDropdown}
        currentDropdown={currentDropdown}
        stateData={stateData}
      />
      {showIdleTimeoutAlert && (
        <SessionAlert
          sessionLogoutTimerData={sessionLogoutTimer}
          setShowIdleTimeoutAlert={setShowIdleTimeoutAlert}
        />
      )}
    </nav>
  ) : (
    <DefaultHeader />
  );
}