import {
  showToastr,
  NeoComponents
} from "@forcepoint/platform-utilityui"
import {
  coreStore,
  GET_PENDING_EULAS,
  SET_PENDING_EULAS,
  APP_URL,
  APP_LOGOUT,
  showFoneAlert,
  closeFoneAlert
} from "@forcepoint/platform-coreui"
import { useState } from "react"

const EulaAcceptanceModal = ({ eulaObj, setGlobalLoader, setEulaObj, popupOpenStatus, setPopupOpenStatus, hideHeader }) => {
  /**
   * To store scopes
   */
  const scopes = eulaObj?.userResponse?.scope

  /**
   * To store pending list
   */
  const pendingList = eulaObj?.pendingEulas ?? null

  /**
   * To store is terms accepted
   */
  const [isTermsAccepted, setIsTermsAccepted] = useState(false)

  /**
 * Submit event to accept EULA
 * @param item
 */
  const submit = async (item) => {
    const url = `${APP_URL}/api/eulas/accept`;
    const headers = {
      Authorization: `Bearer ${eulaObj.token}`
    };

    try {
      // Sending the request to accept the EULA
      const res = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify({
          eulaId: item.ID
        })
      });

      // Handle response based on status code
      if (res.status === 201) {
        handleEulaAccepted(item);
      } else {
        handleEulaError(res);
      }
    } catch (err) {
      handleNetworkError();
    }
  };

  /**
   * Handle successful EULA acceptance
   * @param item
   */
  const handleEulaAccepted = (item) => {
    const updatedList = pendingList?.filter((val) => val.ID !== item.ID);

    // Clear loader and popup, and update EULA object
    setGlobalLoader(true);
    setEulaObj(null);
    setPopupOpenStatus(false);

    // Dispatch appropriate actions based on updated list length
    if (updatedList.length === 0) {
      coreStore.dispatch({
        type: GET_PENDING_EULAS,
        payload: { ...eulaObj, userToken: eulaObj.token }
      });
    } else {
      coreStore.dispatch({
        type: SET_PENDING_EULAS,
        payload: { ...eulaObj, pendingEulas: updatedList, token: eulaObj.token }
      });
    }
  };

  /**
   * Handle EULA acceptance error based on the response status
   * @param res
   */
  const handleEulaError = (res) => {
    let message = 'Some error occurred. Unable to accept EULA';

    if (res.status === 409) {
      message = 'EULA already accepted';
    } else if (res.status === 403) {
      message = 'Please contact administrator for accepting EULA';
    }

    showToastr({
      type: "error",
      message
    });

    coreStore.dispatch({
      type: GET_PENDING_EULAS,
      payload: { ...eulaObj, userToken: eulaObj.token }
    });
  };

  /**
   * Handle network errors or other unforeseen issues
   */
  const handleNetworkError = () => {
    showToastr({
      type: "error",
      message: 'Some error occurred. Unable to accept EULA'
    });
  };

  /**
   * Cancel event
   */
  const cancel = () => {
    coreStore.dispatch({ type: APP_LOGOUT })
    setPopupOpenStatus(false)
    window.location.href = `/platform/login?page=${(
      window.location.pathname + window.location.hash
    ).slice(1)}`
  }

  /**
   * On Click cancel button
   */
  const onClickCancelBtn = () => {
    showFoneAlert({
      type: "warning",
      title: "Cancel Agreement",
      text: "Do you want to cancel the agreement? You will be redirected to login page if you select 'Confirm'",
      positiveBtnText: "Confirm",
      negativeBtnText: "Cancel",
      showNegativeBtn: true,
      positiveBtnCallback: () => {
        cancel()
      },
      negativeBtnCallback: () => {
        closeFoneAlert()
      }
    })
  }

  /**
   * UI Template
   */
  return (
    <div
      className={
        `fone-modal fone-modal-lg ${popupOpenStatus && "show"}`
      }
    >
      <div className="fone-modal-overlay"></div>
      <div className="fone-modal-content">
        {pendingList &&
          pendingList.length > 0 &&
          [pendingList?.[0]].map((item) => {
            return (
              <div key={item.ID}>
                {!hideHeader ? (
                  <div className="fone-modal-header">
                    <h2 className="fone-modal-title">
                      <img
                        style={{ height: "2rem" }}
                        src="/assets/images/f_logo_dark.svg"
                      />
                    </h2>
                  </div>
                ) : null}

                <div className="fone-modal-body eula-modal">
                  <div
                    style={{
                      display: "grid",
                      paddingBottom: "20px",
                      textAlign: "center"
                    }}
                  >
                    <label
                      style={{
                        color: "#414b55",
                        fontSize: "20px",
                        textAlign: "center",
                        margin: "0% !important"
                      }}
                    >
                      <b>FORCEPOINT</b>
                    </label>
                    <label
                      style={{
                        color: "#414b55",
                        fontSize: "20px",
                        margin: "0% !important"
                      }}
                    >
                      <b>NETWORK SECURITY PRODUCTS</b>
                    </label>
                    <label
                      style={{
                        color: "#414b55",
                        fontSize: "20px",
                        textAlign: "center",
                        margin: "0% !important"
                      }}
                    >
                      <b>LICENSE AGREEMENT</b>
                    </label>
                  </div>
                  <div className="eula-acceptance">
                    <p
                      className="eula-html-para"
                      dangerouslySetInnerHTML={{ __html: item.HTMLContent }}
                    ></p>
                  </div>
                </div>

                <div className="eula-footer">
                  <div className="eula-footer-checkbox">
                    <NeoComponents.NeoCheckbox
                      id="terms"
                      onChange={(val) => {
                        setIsTermsAccepted(val?.target?.checked)
                      }}
                      checked={isTermsAccepted}
                      disabled={
                        !scopes?.includes("eula.accept") &&
                        !scopes?.includes("eula.search")
                      }
                    ></NeoComponents.NeoCheckbox>{" "}
                    I accept the term in this agreement
                  </div>
                  <div style={{ display: "flex" }}>
                    <NeoComponents.NeoSecondaryButton
                      onClick={onClickCancelBtn}
                    >
                      Cancel
                    </NeoComponents.NeoSecondaryButton>
                    <span style={{ marginRight: "1rem" }}></span>
                    <NeoComponents.NeoPrimaryButton
                      isDisabled={
                        !isTermsAccepted ||
                        (!scopes?.includes("eula.accept") &&
                          !scopes?.includes("eula.search"))
                      }
                      onClick={() => {
                        submit(item)
                      }}
                    >
                      Confirm
                    </NeoComponents.NeoPrimaryButton>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
export default EulaAcceptanceModal
