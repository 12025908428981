import { FC, useRef, SetStateAction, Dispatch } from "react"
import { isValidOTPDigit } from "../../utility/userprofile.utility"

const OTPInputBox: FC<{
  passcode: string[]
  setPassCode: Dispatch<SetStateAction<string[]>>
}> = ({ passcode, setPassCode }) => {
  // Create an array of refs
  const otpFields = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ]

  const onOTPKeyDown = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target

    if (value.length === 1) {
      // Move to the next input field if available
      if (index < otpFields.length - 1) {
        otpFields[index + 1].current?.focus()
      }
    } else if (value.length === 0) {
      // Move to the previous input field if available
      if (index > 0) {
        otpFields[index - 1].current?.focus()
      }
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const otp = [...passcode]
    otp[index] = event.target.value
    setPassCode(otp)
    onOTPKeyDown(event, index)
  }

  return (
    <>
      {otpFields.map((ref, i) => (
        <input
          key={`otp_box_${ref}`}
          ref={ref}
          className={`otp-input-box ${passcode[i] && !isValidOTPDigit(passcode[i]) ? "invalid" : ""}`}
          type="text"
          value={passcode[i] || ""}
          onChange={(event) => handleInputChange(event, i)}
          maxLength={1}
          autoComplete="off"
          size={1}
          id={`otp_box_${i}`}
        />
      ))}
    </>
  )
}

export default OTPInputBox
