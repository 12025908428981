const DefaultHeader = () => {
    return (
        <nav className="shell-app-header">
            <div className="shell-app-logo">
                <div className="switcher">
                    <a>
                        <img src="/assets/images/menu_dots_icon.svg" />
                    </a>
                </div>

                <h1 className="forceone-title">
                    <span>
                        <img src="/assets/images/f_logo_white.svg" />
                    </span>
                </h1>
            </div>
        </nav>
    );
};

export default DefaultHeader;