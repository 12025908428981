import {
    NeoComponents
} from "@forcepoint/platform-utilityui"
import { CODE_LIST, TIMEZONE_LIST } from "../../userprofile.constant";
import { blockInvalidChar, getMobileValue } from "../../utility/userprofile.utility";
const CountryInfo = ({ userFormObj, userFormErrors, updateDetails, updateOnTouched, selectedTimezone, setSelectedTimezone, countryCode, setCountryCode, countyCodeError, setCountyCodeError }) => {
    return (
        <>
            <div>
                <label style={{ fontSize: "12px" }}>Timezone:</label>
                <div
                    className="hideInputMsg edit-input timezone"
                    style={{
                        width: "95%"
                    }}
                >
                    <NeoComponents.NeoSelectWithSearch
                        id="timezone"
                        placeholder="Timezone"
                        onChange={(event) => {
                            setSelectedTimezone(event)
                            updateDetails("timezone", event.value)
                        }}
                        tabIndex={4}
                        optionList={TIMEZONE_LIST.map((x) => ({
                            text: x.text,
                            value: x.utc
                        }))}
                        selectedOption={selectedTimezone}
                    />
                </div>
            </div>
            <div className="hideInputMsg edit-input">
                <NeoComponents.NeoInput
                    {...{
                        id: "object_guid",
                        placeholder: "Enter objectGUID",
                        containerStyle: { color: "#29343D", width: "90%" },
                        type: "text",
                        label: "objectGUID:",
                        tabIndex: 3,
                        value: userFormObj?.objectGUID?.value,
                        showRequiredLabelIcon: true,
                        errorMessage: userFormErrors?.objectGUID,
                        onChange: (event) =>
                            updateDetails("objectGUID", event.value),
                        onBlur: () => updateOnTouched("objectGUID")
                    }}
                />
            </div>
            <div className="hideInputMsg edit-input">
                <NeoComponents.NeoInput
                    {...{
                        id: "custom_attr_1",
                        placeholder: "Enter Custom Attribute 1",
                        containerStyle: { color: "#29343D", width: "90%" },
                        type: "text",
                        label: "Custom Attribute 1:",
                        tabIndex: 3,
                        value: userFormObj?.customAttr1?.value,
                        showRequiredLabelIcon: true,
                        errorMessage: userFormErrors?.customAttr1,
                        onChange: (event) =>
                            updateDetails("customAttr1", event.value),
                        onBlur: () => updateOnTouched("customAttr1")
                    }}
                />
            </div>
            <div className="hideInputMsg edit-input">
                <NeoComponents.NeoInput
                    {...{
                        id: "custom_attr_2",
                        placeholder: "Enter Custom Attribute 2",
                        containerStyle: { color: "#29343D", width: "90%" },
                        type: "text",
                        label: "Custom Attribute 2:",
                        tabIndex: 3,
                        value: userFormObj?.customAttr2?.value,
                        showRequiredLabelIcon: true,
                        errorMessage: userFormErrors?.customAttr2,
                        onChange: (event) =>
                            updateDetails("customAttr2", event.value),
                        onBlur: () => updateOnTouched("customAttr2")
                    }}
                />
            </div>
            <div>
                <label style={{ fontSize: "12px" }}>Mobile:</label>
                <div
                    className="hideInputMsg edit-input hide-number-arrow"
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "10px",
                        width: "90%",
                        flexDirection: "row"
                    }}
                >
                    <div
                        className="mobile-county-code"
                        style={{ width: "120px" }}
                    >
                        <NeoComponents.NeoSelectWithSearch
                            id="country_code"
                            placeholder="Country Code"
                            onChange={(event) => {
                                const newObj = {
                                    text: event.text,
                                    value: event.value
                                }
                                setCountryCode(newObj)
                                updateDetails("countryCode", event.value)
                                setCountyCodeError(event.value === "")
                            }}
                            tabIndex={4}
                            optionList={CODE_LIST}
                            selectedOption={countryCode}
                        />
                        {countyCodeError && (
                            <label
                                className="custom-label"
                                style={{ color: "#FF3C1C", height: "auto" }}
                            >
                                Please select county code
                            </label>
                        )}
                    </div>
                    <NeoComponents.NeoInput
                        {...{
                            id: "mobile",
                            placeholder: "Enter Mobile",
                            containerStyle: {
                                color: "#29343D",
                                marginTop: "-16px",
                                width: "100%"
                            },
                            type: "number",
                            label: null,
                            tabIndex: 5,
                            value: getMobileValue(userFormObj),
                            showRequiredLabelIcon: false,
                            errorMessage: userFormErrors?.mobile,
                            onChange: (event) =>
                                updateDetails("mobile", event.value),
                            onBlur: () => updateOnTouched("mobile"),
                            onKeyDown: (e) => blockInvalidChar(e),
                            onWheel: (e) => e?.target?.blur()
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default CountryInfo;