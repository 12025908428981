import {
    NeoComponents,
    isEmpty
} from "@forcepoint/platform-utilityui"

const HelpAction = ({helpUrl}) => {

    return !isEmpty(helpUrl) && (
        <li>
            <a id="help_link" href={helpUrl} target="_blank">
                <NeoComponents.NeoIcon
                    name="help"
                    size={22}
                    data-always="true"
                    data-dark-tip="Help"
                />
            </a>
        </li>
    )
};

export default HelpAction;