import { ChangePassFormErrorsInit, ChangePassFormInit } from "../../navigation.model"

/**
 * To store validation error messages for password-related fields.
 */
export const errorMessages = {
  sameAsCurrentPass: "{key} cannot be same as current",
  invalidPass: "{key} entered is incorrect",
  blankPass: "{key} cannot be blank",
  passDoesntMatch: "New Password & Confirm New Password doesn't match"
}

/**
 * To store show password initial state
 */
export const showPassInitialState = {
  currentPassword: false,
  newPassword: false,
  confirmPassword: false,
  showConfirmPassword: false
}

/**
 * To store validate password regex
 */
export const validatePassword =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!@#$%&?<>=[\]{}])[A-Za-z\d -!@#$%&?<>=[\]{}]{8,20}$/

/**
 * To store change password form initilization
 */
export const changePassFormInit: ChangePassFormInit = {
  currentPassword: { value: "", touched: false, text: "Current Password" },
  newPassword: { value: "", touched: false, text: "New Password" },
  confirmPassword: { value: "", touched: false, text: "Confirm New Password" }
}

/**
 * To store change password form errors initilization
 */
export const changePassFormErrorsInit: ChangePassFormErrorsInit = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: ""
}
