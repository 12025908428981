import { useEffect, useState } from 'react';
import { isEmpty } from "@forcepoint/platform-utilityui"
import SwitcherMenus from './SwitcherMenus';
import { getApplications, getMenuList } from '../../utility/header.utility';
import { Applications } from '../../../../navigation.model';

const ApplicationSwitcher = ({ currentDropdown, setCurrentDropdown, stateApplications, activeApp, settingsSidebar }) => {
    /**
     * To set applications
     */
    const [applications, setApplications] = useState<Applications>({
        applications: [],
        platforms: [],
        coreApps: []
    })

    const apps = [
        ...(applications?.coreApps || []),
        ...(applications?.applications || []),
        ...(applications?.platforms || [])
    ]

    const shouldRender = apps.length > (settingsSidebar ? 0 : 1);

    /**
     * To set applications
     */
    useEffect(() => {
        if (!isEmpty(stateApplications)) {
            const appList = getApplications(stateApplications);
            setApplications(appList)
        }
    }, [stateApplications]);

    return (
        <div className="switcher">
            <a
                id="switcher_link"
                onClick={(e) => {
                    e.preventDefault()
                    setCurrentDropdown("applications")
                }}
                onKeyDown={() => { }}
            >
                <img src="/assets/images/menu_dots_icon.svg" />
            </a>

            {shouldRender ? (
                <ul className={`shell-dropdown ${currentDropdown === "applications" ? "show" : ""}`}>
                    {getMenuList(applications).map((x) => (
                        <SwitcherMenus key={x?.menuName} menuName={x?.menuName} menuList={x?.menuList} activeApp={activeApp} settingsSidebar={settingsSidebar} setCurrentDropdown={setCurrentDropdown} />
                    ))}
                </ul>
            ) : null}
        </div>
    );
};

export default ApplicationSwitcher;
