const GlobalLoader = ({ loaderBackground }) => {
  return (
    <div className={`global-loader ${loaderBackground && 'bg-white'}`}>
      <div className="card-block-loader">
        <div className="blockUI"></div>
        <div className="sk-swing sk-primary">
          <div className="sk-swing-dot"></div>
          <div className="sk-swing-dot"></div>
        </div>
      </div>
    </div>
  )
}

export default GlobalLoader
